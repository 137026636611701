import { usePaymentApiClient, useWebApiClient } from '@apiClients'
import { useMutation } from '@tanstack/react-query'

export const useCreateS3ProjectResource = (projectId: string) => {
  const client = usePaymentApiClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`resources/s3/project/${projectId}`, {
        method: 'post',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
  })
}

export const useUploadToS3 = () => {
  const client = useWebApiClient()
  return useMutation({
    mutationFn: async (data: {
      file: File
      isPublicResource?: boolean
    }): Promise<{ fileUrl: HttpsUrl; s3Uri: S3Uri }> => {
      const { file, isPublicResource = false } = data

      const fileExtension = file.name.split('.').pop()

      // Get the presigned URL
      const { uploadUrl, fileUrl, s3Uri } = await client(
        `resources/s3/presigned-upload-url?public=${isPublicResource}&fileExtension=${fileExtension}`,
      )

      // Upload the file directly to S3 using the presigned URL
      await fetch(uploadUrl, {
        method: 'put',
        body: file,
      })

      return { fileUrl, s3Uri }
    },
  })
}
