import {
  useGetProjectSocials,
  useGetProjectTheme,
  useGetProjectWelcomeContent,
} from '@apiServices'
import { Alert, Col, Text, TokensoftPoweredByIcon } from '@components'
import {
  DEFAULT_WELCOME_BG_COLOR,
  DEFAULT_WELCOME_BG_COLOR_LEGACY,
} from '@constants'
import { useAnalytics } from '@contexts'
import { LAYOUT_TYPE } from '@enums'
import { FadeInImage, ProjectSocials } from '@newComponents'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { getIPFSLink } from '@utils'
import { motion } from 'framer-motion'
import { useMemo } from 'react'
import { FaExclamationCircle } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import { ProjectWelcomeContent } from 'tokensoft-shared-types'
import './connect.css'

export const ConnectPage = () => {
  useAnalytics('/welcome')

  const { pathname } = useLocation()
  const { data: projectSocials } = useGetProjectSocials()
  const { data: projectTheme } = useGetProjectTheme()
  const { data: projectWelcomeContent } = useGetProjectWelcomeContent()

  const socialLinkColor = projectTheme?.colors.primary

  const isRegistration = pathname.startsWith('/admin/register')

  const getWelcomeTitle = (isRegistration: boolean, projectTitle?: string) => {
    return (
      projectTitle ||
      (isRegistration
        ? "Let's Get Started"
        : 'Delivering Integrity and Compliance')
    )
  }

  const getWelcomeSubtitle = (
    isRegistration: boolean,
    projectSubtitle?: string,
  ) => {
    return (
      projectSubtitle ||
      (isRegistration
        ? 'Thank you for choosing Tokensoft. Connect a wallet to setup your project.'
        : 'Enterprise services & tools for the leading blockchain foundations.')
    )
  }

  const getTitleAlignment = (layoutVersion?: string) => {
    switch (layoutVersion) {
      case LAYOUT_TYPE.TITLE_ICON_CENTERED:
      case LAYOUT_TYPE.TITLE_ONLY_100_CENTERED:
        return 'text-center'
      case LAYOUT_TYPE.TITLE_ONLY_100_LEFT:
        return 'text-left'
      case LAYOUT_TYPE.TITLE_ONLY_100_RIGHT:
        return 'text-right'
      default:
        return undefined
    }
  }

  const isCustomWelcomeContent = (
    projectWelcomeContent: ProjectWelcomeContent,
  ) => {
    return (
      (projectWelcomeContent.backgroundColor !==
        DEFAULT_WELCOME_BG_COLOR_LEGACY &&
        projectWelcomeContent.backgroundColor !== DEFAULT_WELCOME_BG_COLOR) ||
      projectWelcomeContent.backgroundImage !== null ||
      projectWelcomeContent.bodyText !== null
    )
  }

  const getWelcomeContent = (isRegistration: boolean) => {
    const usingCustomWelcomeContent =
      projectWelcomeContent !== undefined
        ? isCustomWelcomeContent(projectWelcomeContent)
        : false

    return {
      welcomeTitle: getWelcomeTitle(
        isRegistration,
        projectWelcomeContent?.title,
      ),
      welcomeSubtitle: getWelcomeSubtitle(
        isRegistration,
        projectWelcomeContent?.subtitle,
      ),
      containerClass: usingCustomWelcomeContent
        ? 'domain-content'
        : 'tokensoft-content',
      welcomeLayoutVersion: projectWelcomeContent?.layoutVersion,
      welcomeLogo:
        projectWelcomeContent?.logo !== undefined
          ? getIPFSLink(projectWelcomeContent.logo)
          : '#',
      welcomeTitleAlignment: getTitleAlignment(
        projectWelcomeContent?.layoutVersion,
      ),
    }
  }

  const {
    welcomeTitle,
    welcomeSubtitle,
    containerClass,
    welcomeLayoutVersion,
    welcomeLogo,
    welcomeTitleAlignment,
  } = useMemo(
    () => getWelcomeContent(isRegistration),
    [projectWelcomeContent, isRegistration],
  )

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.6, staggerChildren: 0.2 },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.4 } },
  }

  const backgroundStyle = useMemo(() => {
    if (projectWelcomeContent?.backgroundImage) {
      let backgroundImageUrl: string | undefined
      if (projectWelcomeContent.backgroundImage.startsWith('ipfs://')) {
        backgroundImageUrl = getIPFSLink(projectWelcomeContent.backgroundImage)
      } else {
        backgroundImageUrl = projectWelcomeContent.backgroundImage
      }

      return {
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }
    }
    return {
      backgroundColor: projectWelcomeContent?.backgroundColor || 'transparent',
    }
  }, [projectWelcomeContent])

  return (
    <div className='welcome-content-body'>
      {import.meta.env.VITE_PROCESS_ENV !== 'production' && (
        <Alert
          type='alert-info'
          className='header-alert w-full square-borders !fixed top-1'
          icon={<FaExclamationCircle className='danger' />}
        >
          <div className='flex items-center w-full'>
            This site is used for testing purposes only. Do not submit personal
            information.
          </div>
        </Alert>
      )}

      {projectWelcomeContent && (
        <div
          key='background'
          className='absolute inset-0 z-0'
          style={backgroundStyle}
        />
      )}

      <motion.div
        className={`welcome-content welcome-color ${containerClass} relative z-10`}
        initial='hidden'
        animate='visible'
        variants={containerVariants}
      >
        <div
          className={`flex flex-col ${projectWelcomeContent?.alignment || 'center-center'} w-full px-32 mb-4`}
        >
          {projectWelcomeContent &&
          isCustomWelcomeContent(projectWelcomeContent) &&
          projectWelcomeContent.bodyText ? (
            <motion.div
              variants={itemVariants}
              dangerouslySetInnerHTML={{
                __html: projectWelcomeContent.bodyText,
              }}
            />
          ) : (
            <>
              {welcomeLayoutVersion === LAYOUT_TYPE.TITLE_ICON_CENTERED &&
                welcomeLogo !== undefined && (
                  <FadeInImage
                    src={welcomeLogo}
                    className='h-32 mx-auto'
                    alt='Welcome Logo'
                    width='auto'
                  />
                )}
              <Text
                className={`mb-5 mt-6 welcome-title ${welcomeTitleAlignment}`}
              >
                {welcomeTitle}
              </Text>
              <motion.p
                className={`welcome-subtitle ${welcomeTitleAlignment}`}
                variants={itemVariants}
              >
                {welcomeSubtitle}
              </motion.p>
            </>
          )}

          <Col className='mt-12' xalign='center'>
            <motion.div className='mt-4' variants={itemVariants}>
              <ConnectButton />
            </motion.div>

            {projectWelcomeContent && (
              <motion.div className='mt-5' variants={itemVariants}>
                <TokensoftPoweredByIcon />
              </motion.div>
            )}
          </Col>
        </div>

        <motion.div className='h-[36px]' variants={itemVariants}>
          <ProjectSocials
            socialLinks={projectSocials}
            color={socialLinkColor}
          />
        </motion.div>
      </motion.div>
    </div>
  )
}
