import { generatePeriodicTranches } from '@components'
import { useTSWagmi } from '@contexts'
import { DEPLOY_VESTING_TYPE_OPTIONS } from '@enums'
import {
  getContinuousVestingMerkleABI,
  getTrancheVestingMerkleABI,
} from '@utils'
import { simulateContract, writeContract } from '@wagmi/core'
import { useState } from 'react'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useUpdateVestingConfig = () => {
  const { wagmiConfig } = useTSWagmi()
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const getTranches = (args: any) => {
    const tranches = generatePeriodicTranches(
      args.startTime,
      args.cliffTime,
      args.endTime,
    )
    const tranchesArray = tranches.map((t) => {
      return [t.time, t.vestedFraction]
    })
    return [tranchesArray]
  }

  const write = async (
    chainId: any,
    contractAddress: any,
    args: any,
    vestingType: `monthly` | `continuous` | `instant`,
  ) => {
    setSubmitting(true)
    console.log('updating distributor vesting config')

    const abi =
      vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY
        ? getTrancheVestingMerkleABI()
        : getContinuousVestingMerkleABI()
    const functionName =
      vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY
        ? 'setTranches'
        : 'setVestingConfig'

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi,
        functionName,
        chainId,
        args:
          vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY
            ? getTranches(args)
            : [args.startTime, args.cliffTime, args.endTime],
      } as any)

      const writeContractResponse = await writeContract(wagmiConfig, request)
      setTransactionHash(writeContractResponse)
      console.log(
        'update distributor vesting config transaction response',
        writeContractResponse,
      )
      return writeContractResponse
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  }
}
