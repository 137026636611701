import { NavHeader, NotificationPopover } from '@components'
import { useAuth } from '@contexts'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import './header.css'

export const Header = () => {
  const { isAuthenticated } = useAuth()

  return (
    <>
      <NavHeader />
      <div className='header'>
        <div className='header-content'>
          <nav className='navbar navbar-expand'>
            <div className='navbar-collapse justify-between'>
              <div className='header-left' />
              {isAuthenticated ? (
                <ul className='navbar-nav header-right main-notification'>
                  <li className='nav-item dropdown notification_dropdown'>
                    <NotificationPopover />
                  </li>
                  <li className='nav-item dropdown header-profile'>
                    <ConnectButton
                      accountStatus={{
                        smallScreen: 'avatar',
                        largeScreen: 'full',
                      }}
                      showBalance={{
                        smallScreen: false,
                        largeScreen: true,
                      }}
                      chainStatus={{
                        smallScreen: 'icon',
                        largeScreen: 'full',
                      }}
                    />
                  </li>
                </ul>
              ) : null}
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}
