import { useGetChains, useGetOraclePrice } from '@apiServices'
import { useTSWagmi } from '@contexts'
import { DeployOptions } from '@custom-types/deploy-options'
import { add, div, exp, fix, getErc20ABI, mult, toBigNumber } from '@utils'
import { useContractWrite } from '@utils/contract-interaction'
import { readContract } from '@wagmi/core' // waitForTransactionReceipt
import BigNumber from 'bignumber.js'
import { useState } from 'react'
import { Abi, Hex, ReadContractParameters, TransactionReceipt } from 'viem'
import { simulateContract } from 'viem/actions'
import {
  useReadContract,
  useAccount as useWagmiAccount,
  useWaitForTransactionReceipt,
} from 'wagmi'

const NATIVE_TOKEN_DECIMALS = 18

type ContractFunctionExecutionParams = Parameters<typeof simulateContract>[1]
type ContractFunctionArgs = Exclude<
  ContractFunctionExecutionParams['args'],
  undefined
>

export const useDeployDistributorWithFactory = (options: DeployOptions) => {
  const { chain: chainUserIsConnectedTo } = useWagmiAccount()
  const { wagmiConfig } = useTSWagmi()
  const { data: chains } = useGetChains()
  const { executeContractWrite } = useContractWrite()
  const [transactionHash, setTransactionHash] = useState<Hex | undefined>()
  const [isDeploying, setIsDeploying] = useState<boolean>(false)
  const [args, setArgs] = useState<ContractFunctionArgs>([])
  const { mutate: getOraclePrice } = useGetOraclePrice()

  const currentChainInfo = chains?.find(
    (n) => n.id === chainUserIsConnectedTo?.id,
  )

  const experimental = options.useExperimentalContractFeatures

  const config: ReadContractParameters = {
    address: options.address,
    abi: options.abi,
    functionName: 'predictDistributorAddress',
    args,
  }

  const { data: deployedAddress }: { data: Hex | undefined } = useReadContract({
    ...config,
    query: {
      enabled: args.length > 0,
    },
  })

  const write = async ({ args }: { args: ContractFunctionArgs }) => {
    setArgs(args)
    setIsDeploying(true)

    try {
      let value: BigNumber | undefined

      if (experimental) {
        const predictedAddress = await readContract(wagmiConfig, {
          ...config,
          args,
        })

        const tokenAddress = args[0] as Hex
        const totalClaimableAmount = args[1] as BigNumber

        await executeContractWrite({
          address: tokenAddress,
          abi: getErc20ABI() as Abi,
          functionName: 'approve',
          args: [
            predictedAddress,
            add(totalClaimableAmount, div(totalClaimableAmount, 100)),
          ],
        })

        const result = await new Promise<
          { valid: true; price: string } | { valid: false }
        >((resolve, reject) => {
          getOraclePrice(
            {
              networkId: currentChainInfo?.id,
              oracleAddress: currentChainInfo?.nativePriceOracleAddress,
            },
            { onSuccess: resolve, onError: reject },
          )
        })

        if (result.valid === false) {
          throw new Error('failed to get valid result from pricing API')
        }

        const nativeOraclePrice = result.price

        value = toBigNumber(
          fix(
            mult(
              div('100000001', nativeOraclePrice),
              exp(10, NATIVE_TOKEN_DECIMALS),
            ),
            '0',
          ),
        )
      }

      const writeContractResponse = await executeContractWrite({
        address: config.address,
        abi: options.abi,
        functionName: 'deployDistributor',
        args: [...args],
        value: value ? BigInt(value.toString()) : undefined,
      })

      setTransactionHash(writeContractResponse)
      return writeContractResponse
    } finally {
      setIsDeploying(false)
    }
  }

  const response = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    deployedAddress,
    write,
    isDeploying,
    response: { ...response },
    receipt: response?.data || ({} as TransactionReceipt),
    error: response?.error,
  }
}
