import { ALLOWED_CLASSES, Col, FormLabel, Row } from '@components'
import classNames from 'classnames'
import { FC, ReactNode, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import './input-group.css'

type InputGroupProps = {
  allowNegative?: boolean
  append?: ReactNode
  className?: string
  decimalScale?: any
  dir?: string
  disabled?: boolean
  error?: ReactNode
  id?: string
  isAllowed?: any
  label?: string
  labelSuffix?: ReactNode
  max?: any
  maxLength?: number
  min?: any
  name?: string
  // Used to forward the event up. Normal event propagation doesn't work because the handler here isn't bound to the
  // underlying input directly; it's treated as a React property.
  onChange?: any
  onFocus?: any
  flexPrepend?: boolean
  onKeyPress?: any
  placeholder?: string
  prefix?: string
  prepend?: ReactNode
  readonly?: boolean
  required?: boolean
  textareaStyles?: {
    resizeTextarea?: boolean
    height?: string
  }
  thousandSeparator?: boolean
  type?: string
  valid?: boolean
  value: any
  width?: Pick<React.ComponentProps<typeof Row>, 'width'>['width']
  hint?: string
  colWidths?: {
    first?: (typeof ALLOWED_CLASSES)['width'][number]
    second?: (typeof ALLOWED_CLASSES)['width'][number]
  }
}

export const InputGroup: FC<InputGroupProps> = ({
  allowNegative = false,
  append,
  className,
  decimalScale = 0,
  dir = 'ltr',
  disabled,
  error,
  id,
  isAllowed,
  label,
  labelSuffix,
  max,
  maxLength,
  min,
  name,
  onChange = () => {},
  onFocus = () => {},
  flexPrepend,
  onKeyPress,
  placeholder,
  prefix,
  prepend,
  readonly = false,
  required = false,
  textareaStyles = { resizeTextarea: true, height: '24' },
  thousandSeparator = false,
  type = 'text',
  valid = true,
  value,
  width = 'full',
  hint,
  colWidths = {},
}) => {
  const [touched, setTouched] = useState<boolean>(false)
  const [requiredValidationMessage, setRequiredValidationMessage] =
    useState<Maybe<string>>(null)

  const key = id || name

  const handleNumberFormatChange = ({ value }: any, event: any) => {
    onChange && onChange({ value }, event)
  }

  useEffect(() => {
    if (!readonly && required && !value && touched) {
      setRequiredValidationMessage(`${label || name} is required`)
    } else {
      setRequiredValidationMessage(null)
    }
  }, [value, touched])

  return (
    <>
      <Row
        width={width}
        className={classNames(
          'input-group-container',
          className,
          requiredValidationMessage || error ? 'invalid' : '',
          !requiredValidationMessage && !valid ? 'invalid' : '',
        )}
      >
        <Col width={colWidths.first}>
          {label && (
            <FormLabel data-testid={`${key}-label`} className={'flex flex-row'}>
              <label className={labelSuffix ? 'pr-2' : ''} htmlFor={name}>
                {label}
                {required && ' *'}
              </label>
              {labelSuffix}
            </FormLabel>
          )}
          {hint && !error && !requiredValidationMessage && (
            <span className='text-sm text-gray-500 mt-1'>{hint}</span>
          )}
        </Col>
        <Col
          width={colWidths.second}
          className={classNames('input-group', readonly ? 'readonly' : '')}
        >
          <div className={classNames(flexPrepend ? 'flex' : '')}>
            {prepend}
            {type === 'number' ? (
              <NumberFormat
                data-testid={`${key}`}
                data-lpignore='true'
                name={name}
                // Don't show 1Pass
                // https://1password.community/discussion/117501/as-a-web-developer-how-can-i-disable-1password-filling-for-a-specific-field/p4
                data-1p-ignore='true'
                autoComplete='off'
                className='form-control'
                displayType='input'
                type='text'
                allowNegative={allowNegative}
                decimalScale={decimalScale}
                value={value}
                placeholder={placeholder}
                onValueChange={handleNumberFormatChange}
                readOnly={readonly}
                isAllowed={isAllowed}
                prefix={prefix}
                thousandSeparator={thousandSeparator}
                dir={dir}
                onBlur={() => setTouched(true)}
                disabled={disabled}
              />
            ) : type === 'textarea' ? (
              <textarea
                data-testid={`${key}`}
                className={`textarea form-control ${!textareaStyles.resizeTextarea ? 'resize-none' : ''} ${textareaStyles.height ? `!h-${textareaStyles.height}` : ''}`}
                placeholder={placeholder || ''}
                required={required}
                readOnly={readonly}
                id={key}
                name={name}
                value={value || ''}
                maxLength={maxLength}
                onChange={onChange}
                onFocus={onFocus}
                dir={dir}
                onBlur={() => setTouched(true)}
                disabled={disabled}
              />
            ) : (
              <input
                data-testid={`${key}`}
                data-lpignore='true'
                // Don't show 1Pass
                // https://1password.community/discussion/117501/as-a-web-developer-how-can-i-disable-1password-filling-for-a-specific-field/p4
                data-1p-ignore='true'
                className='form-control'
                placeholder={placeholder || ''}
                required={required}
                readOnly={readonly}
                id={key}
                name={name}
                value={value || ''}
                onChange={onChange}
                type={type}
                max={max}
                min={min}
                onFocus={onFocus}
                dir={dir}
                onKeyPress={onKeyPress}
                maxLength={maxLength}
                onBlur={() => setTouched(true)}
                disabled={disabled}
              />
            )}
            {append}
          </div>
          <div>
            {requiredValidationMessage && (
              <span
                data-testid={`${key}-required-error`}
                className='form-control-error'
              >
                {requiredValidationMessage}
              </span>
            )}
            {error && !requiredValidationMessage && (
              <span data-testid={`${key}-error`} className='form-control-error'>
                {error}
              </span>
            )}
            {/* this holds space below the input for an error message when there is no error */}
            {!error && !requiredValidationMessage && (
              <span data-testid={`${key}-valid`} className='transparent mb-2'>
                valid
              </span>
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}
