import { useAdminApiClient, usePaymentApiClient } from '@apiClients'
import { useGetProject } from '@apiServices'
import { useAccount, useAuth, useNetworks } from '@contexts'
import { LegacyPurchasesByTenants } from '@customTypes'
import { useAsync } from '@hooks'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { uniqueValues } from '@utils'
import { useEffect, useState } from 'react'

export const useGetPurchases = (
  buyerAddress?: string,
  chainId?: number,
  eventId?: string,
  projectId?: string,
) => {
  const {
    user: { walletAddress },
  } = useAuth()
  const { supportedNetworks } = useNetworks()
  const { data: project } = useGetProject()
  const client = usePaymentApiClient()
  const { run, data } = useAsync()
  const [searchResults, setSearchResults] = useState<any[]>([])
  const [moreResults, setMoreResults] = useState<Maybe<any>>(null)
  const [loadingComplete, setLoadingComplete] = useState<any[]>([])
  const [reload, setReload] = useState<Date | null>(null)

  const PAGE_SIZE: number = 10

  /**
   * Build API request
   */
  const getClient = (request: any) => {
    let url = 'purchases/search'

    if (request.nextLink) {
      url += '?' + request.nextLink.split('?')[1]
    } else {
      url += `?offset=0&queryLimit=${PAGE_SIZE}`
    }

    return client(url, {
      method: 'post',
      data: request.searchRequest,
    })
  }

  /**
   * Iterate over each network and execute query
   */
  useEffect(() => {
    if (!reload || project === undefined) {
      return
    }

    const buyerId = buyerAddress
      ? buyerAddress.toLowerCase()
      : walletAddress.toLowerCase()

    if (chainId && eventId) {
      run(
        getClient({
          searchRequest: {
            buyerId: buyerId,
            chainId: chainId,
            eventId: eventId,
            projectId: project.id,
          },
        }),
      )
    } else {
      for (let i = 0; i < supportedNetworks.length; i++) {
        const network = supportedNetworks[i]

        /* Setup default query parameters */
        run(
          getClient({
            searchRequest: {
              buyerId: buyerId,
              chainId: network.id,
              eventId: eventId,
              projectId: project.id,
            },
          }),
        )
      }
    }
  }, [reload])

  /**
   * Accumulate data and trigger fetching of additional pages (if available)
   */
  useEffect(() => {
    if (!data) {
      return
    }

    if (data?.purchases?.length > 0) {
      setSearchResults(uniqueValues(searchResults.concat(data.purchases)))

      if (data.nextLink) {
        setMoreResults(data)
      } else {
        setLoadingComplete(loadingComplete.concat([data.searchRequest.chainId]))
      }
    } else {
      setLoadingComplete(loadingComplete.concat([data.searchRequest.chainId]))
    }
  }, [data])

  /**
   * Fetch additional page of data
   */
  useEffect(() => {
    if (moreResults) {
      run(getClient(moreResults))
    }
  }, [moreResults])

  const refresh = () => {
    setSearchResults([])
    setMoreResults(null)
    setLoadingComplete([])
    setReload(new Date())
  }

  useEffect(() => {
    if (project !== undefined && walletAddress) {
      refresh()
    }
  }, [project, walletAddress])

  const loading = chainId
    ? loadingComplete.length !== 1
    : loadingComplete.length !== supportedNetworks.length
  return { results: searchResults, loading, refresh: refresh }
}

export const useGetLegacyPurchases = () => {
  const client = usePaymentApiClient()
  const { run, data, isPending, error } = useAsync()
  const { account, loading: loadingAccount } = useAccount()
  const [purchases, setPurchases] = useState<LegacyPurchasesByTenants[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (loadingAccount) {
      setLoading(true)
      return
    }

    if (isPending) {
      setLoading(true)
      return
    }

    if (!account.emailVerified || !account.email) {
      setLoading(false)
      setPurchases([])
      return
    }

    run(
      client('purchases/search-legacy', {
        method: 'get',
      }),
    )
  }, [account, loadingAccount])

  useEffect(() => {
    if (!data) {
      return
    }

    const purchasesByTenants: LegacyPurchasesByTenants[] = []
    const purchases = data?.purchases || []

    purchases.forEach((purchase: any) => {
      const tenantId = purchase?.tenantId
      const tenantName = purchase.tenantName
      const logo = purchase.logo

      const existingTenant = purchasesByTenants.find(
        (tenant) => tenant?.id === tenantId,
      )
      if (existingTenant) {
        const updatedPurchases = [...(existingTenant.purchases ?? []), purchase]
        existingTenant.purchases = updatedPurchases
      } else {
        const newTenant = {
          id: tenantId,
          tenantName,
          logo,
          email: purchase?.email,
          purchases: [purchase],
        }
        purchasesByTenants.push(newTenant)
      }
    })

    // Sort purchases by date
    purchasesByTenants.forEach(({ purchases }, i) => {
      const sortedPurchases =
        purchases?.sort(function (a, b) {
          return (
            new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
          )
        }) ?? []

      purchasesByTenants[i].purchases = sortedPurchases
    })

    setPurchases(purchasesByTenants)
    setLoading(false)
  }, [data])

  return {
    loading,
    purchases,
    error,
  }
}

export const useGetAdminLegacyPurchases = () => {
  const client = useAdminApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`purchases/search-legacy`, {
        method: 'post',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}

export const useGetEventPurchases = (eventId: string) => {
  const client = usePaymentApiClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`purchases/event/${eventId}`, {
        method: 'post',
        data,
      })
    },
  })
}

export const usePurchaseCompleted = () => {
  const client = usePaymentApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`purchases/completed`, {
        method: 'post',
        data: data,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['purchase'] })
    },
  })
}
