import { CountriesData } from '@customTypes'
import { useAsync } from '@hooks'
import { countryList } from '@utils'
import axios from 'axios'
import { useEffect } from 'react'

const getCountryCode = async (): Promise<string> => {
  const response = await axios('https://countrycode.tokensoft.io')
  return response?.data.country || ''
}

export const useCountryCode = () => {
  const { data: countryCode, isPending, run } = useAsync()

  useEffect(() => {
    run(getCountryCode())
  }, [run])

  return { countryCode, loading: isPending }
}

export const allowedRegionsToCountryList = (
  allowedRegions: string[],
): CountriesData[] => {
  if (!allowedRegions?.length) {
    return []
  }

  const CountryList = countryList()

  return allowedRegions.map((value: string) => {
    return {
      label: CountryList.getLabel(value),
      value: value,
    }
  })
}

export const blockedRegionsFromCountryList = (
  list: CountriesData[],
): CountriesData[] => {
  if (!list?.length) {
    return []
  }

  const CountryList = countryList()
  return CountryList.getInverseList(list)
}
