import { Badge } from './badge'

type BadgeList = {
  titles: string[]
}

export const BadgeList = ({ titles }: BadgeList) => {
  return (
    <div className='flex flex-row gap-1 overflow-hidden'>
      {titles.map((title, i) => (
        <Badge key={i} text={title} />
      ))}
    </div>
  )
}
