import { useCreateEvent } from '@apiServices'
import {
  BackButton,
  Button,
  ButtonRow,
  ContinueButton,
  DocumentsList,
  DocumentUploadSaveButton,
  DocumentUploadSection,
  EventCreateConfirmation,
  EventEligibilitySetup,
  EventPageSetupContent,
  EventPageSetupGeneral,
  FrontArrowIcon,
  GoToStepButton,
  PageSubtitle,
  PageTitle,
  PreviewPage,
  PreviewPageButton,
  Step,
  StepFooter,
  StepHeader,
  Text,
  WizardBreadcrumb,
} from '@components'
import {
  DocumentUploadProvider,
  useAccount,
  useProject,
  useToast,
  WizardContext,
} from '@contexts'
import { CreateEventPayload, EventWizardState } from '@customTypes'
import { DOCUMENT_CATEGORY, EVENT_TYPE } from '@enums'
import {
  complianceTiertoKycRestriction,
  formatCreateEventDocumentsPayload,
  localToUtcDateTime,
  walletRequirementsString,
} from '@utils'
import { useState } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { HiRocketLaunch } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'
import { Wizard } from 'react-use-wizard'
import './event-create-document.css'

interface LaunchEventWizardPageProps {
  eventType: EVENT_TYPE
  documentCategory: DOCUMENT_CATEGORY
  adjustEventCreationFields?: (
    wizardState: CreateEventPayload,
  ) => CreateEventPayload
}

export const LaunchEventWizardPage = ({
  eventType,
  documentCategory,
  adjustEventCreationFields,
}: LaunchEventWizardPageProps) => {
  const { account } = useAccount()
  const { showErrorToast } = useToast()
  const navigate = useNavigate()
  const { project } = useProject()
  const [wizardState, setWizardState] = useState<EventWizardState>({
    eventType: eventType,
    isEditingDocuments: undefined,
  })
  const { mutate: createEvent, isPending: createEventLoading } =
    useCreateEvent()

  const handleLaunchEvent = () => {
    const allowedRegions = wizardState?.allowedCountryList?.map(
      (country) => country.value,
    )

    const payload: CreateEventPayload = {
      allowedRegions: allowedRegions ?? [],
      bodyHeader: wizardState?.bodyTitle ?? '',
      contentBodyClosed: wizardState?.contentBodyClosed ?? '',
      contentBodyOpen: wizardState?.contentBodyOpen ?? '',
      contentBodyUpcoming: wizardState?.contentBodyUpcoming ?? '',
      description: wizardState?.description ?? '',
      eventType: eventType,
      contentHeaderBgColor: wizardState?.contentHeaderBgColor ?? '',
      contentHeaderBgImage: wizardState?.contentHeaderBgImage ?? '',
      contentHeaderFontColor: wizardState?.contentHeaderFontColor ?? '',
      contentHeaderImage: wizardState?.contentHeaderImage ?? '',
      contentHeaderSubtitle: wizardState?.contentHeaderSubtitle ?? '',
      contentHeaderTitle: wizardState?.contentHeaderTitle ?? '',
      overviewImageUrl: wizardState?.overviewImageUrl,
      name: wizardState?.name ?? '',
      privacyConfiguration: wizardState?.privacyConfiguration,
      projectId: project.id,
      themeLayoutVersion: wizardState?.themeLayoutVersion ?? '',
      walletRequirements: wizardState?.walletRequirements
        ? walletRequirementsString(wizardState.walletRequirements)
        : '',

      documents: formatCreateEventDocumentsPayload(
        documentCategory,
        wizardState?.documents,
        allowedRegions,
      ),
      kycRestriction:
        complianceTiertoKycRestriction(wizardState?.complianceTier) ?? '',
      registrationEndTime: wizardState?.endTime
        ? localToUtcDateTime(wizardState.endTime, account?.timezone)
        : '',
      registrationStartTime: localToUtcDateTime(
        wizardState?.startTime ?? '',
        account?.timezone,
      ),
      useExperimentalContractFeatures:
        wizardState?.useExperimentalContractFeatures,
    }

    // Allow each event type to populate its own fields if desired
    if (adjustEventCreationFields) {
      adjustEventCreationFields(payload)
    }

    createEvent(payload, {
      onSuccess: (data) => {
        navigate(`/admin/project/${project?.id}/event/${data.id}`)
      },
      onError: (error) => {
        showErrorToast({ description: error.message })
      },
    })
  }

  const renderPreviewContent = (onContinue: any) => {
    return <PreviewPage context={wizardState} onContinue={onContinue} />
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 1</WizardBreadcrumb>
            <PageTitle>General Information</PageTitle>
            <PageSubtitle>
              Let&apos;s start with some general info about your event. Please
              fill out your event title, subtitle and pick a start date for your
              open registration period. This information will be part of your
              end-user facing event.
            </PageSubtitle>
          </StepHeader>

          <EventPageSetupGeneral
            wizardState={wizardState}
            editMode={false}
            updateWizardState={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton
                onClick={() =>
                  navigate(`/admin/project/${project?.id}/event/launch`)
                }
              />
              <ContinueButton
                disabled={!wizardState?.eventPageGeneralFormValid}
              />
            </ButtonRow>
          </StepFooter>
        </Step>
        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 2</WizardBreadcrumb>
            <PageTitle>Eligibility</PageTitle>
            <PageSubtitle>
              Decide who can participate in your event. Gather the requirements
              from your compliance manual and set them here.
            </PageSubtitle>
          </StepHeader>

          <EventEligibilitySetup
            wizardState={wizardState}
            setWizardState={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton />
              <ContinueButton disabled={!wizardState?.eligibilityFormValid} />
            </ButtonRow>
          </StepFooter>
        </Step>

        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 3</WizardBreadcrumb>
            <PageTitle>Document Setup</PageTitle>
            <PageSubtitle>
              Please upload the documents you would like your event participants
              to review and accept.
            </PageSubtitle>
          </StepHeader>

          <DocumentsList
            wizardState={wizardState}
            setWizardState={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton />
              <GoToStepButton
                stepToGo={4}
                icon={<FrontArrowIcon />}
                disabled={!wizardState?.documentsFormValid}
              />
            </ButtonRow>
          </StepFooter>
        </Step>

        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 3</WizardBreadcrumb>
            <PageTitle>Document Setup</PageTitle>
            <PageSubtitle>
              Please upload the documents you would like your event participants
              to review and accept.
            </PageSubtitle>
          </StepHeader>

          <DocumentUploadProvider
            wizardState={wizardState}
            setWizardState={setWizardState}
          >
            <DocumentUploadSection />

            <StepFooter>
              <ButtonRow place='between'>
                <BackButton label='Cancel' />
                <DocumentUploadSaveButton />
              </ButtonRow>
            </StepFooter>
          </DocumentUploadProvider>
        </Step>

        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 4</WizardBreadcrumb>
            <PageTitle>Event Content Setup</PageTitle>
            <PageSubtitle>
              The Event Page is displayed to your users as a preview page for an
              upcoming event. This page is designed to introduce them to the
              process and to provide general context about your project.
            </PageSubtitle>
          </StepHeader>

          <EventPageSetupContent
            wizardState={wizardState}
            updateWizardState={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <GoToStepButton fill={'outline'} stepToGo={2} label='Back' />
              <PreviewPageButton
                previewContent={renderPreviewContent}
                disabled={!wizardState?.eventPageSetupFormValid}
                label={'Preview and Continue'}
              />
            </ButtonRow>
          </StepFooter>
        </Step>

        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 5</WizardBreadcrumb>
            <PageTitle>Event Details Confirmation</PageTitle>
            <PageSubtitle>
              Before proceeding please make sure that your details that have
              been agreed to are correct.
            </PageSubtitle>
          </StepHeader>

          <EventCreateConfirmation wizardState={wizardState} />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton />
              <Button disabled={createEventLoading} onClick={handleLaunchEvent}>
                {createEventLoading ? (
                  <>
                    <div className='animate-spin'>
                      <AiOutlineLoading3Quarters size={16} />
                    </div>
                    <Text className='pl-2'>Configuring...</Text>
                  </>
                ) : (
                  <>
                    <HiRocketLaunch />
                    <Text className='ml-2'>Create Event</Text>
                  </>
                )}
              </Button>
            </ButtonRow>
          </StepFooter>
        </Step>
      </Wizard>
    </WizardContext.Provider>
  )
}
