import { useGetPurchaseSignature } from '@apiServices'
import { useTSWagmi } from '@contexts'
import * as Sentry from '@sentry/react'
import { getFlatPriceSaleABI } from '@utils'
import { simulateContract, writeContract } from '@wagmi/core'
import BigNumber from 'bignumber.js'
import { useState } from 'react'
import { Abi } from 'viem'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useBuyWithToken = (chainId: ChainId, saleAddress: EvmAddress) => {
  const { wagmiConfig } = useTSWagmi()
  const [isPendingSubmitTransaction, setIsPendingSubmitTransaction] =
    useState(false)
  const [submitTransactionError, setSubmitTransactionError] =
    useState<Error | null>(null)
  const [transactionHash, setTransactionHash] =
    useState<Maybe<EvmAddress>>(null)

  const {
    data: purchaseSignatureData,
    isPending: isPendingPurchaseSignature,
    error: purchaseSignatureError,
  } = useGetPurchaseSignature(saleAddress)

  const buyWithToken = async (
    tokenAddress: EvmAddress,
    tokenValueInWei: BigNumber, // total cost in the ERC20
    platformFeeRecipient: Maybe<EvmAddress>,
    platformFeeInDollars: BigNumber,
    platformFeeInWei: BigNumber,
  ) => {
    if (!purchaseSignatureData) return

    const {
      userLimit,
      expiresAt: signatureExpiresAt,
      signature,
    } = purchaseSignatureData

    setIsPendingSubmitTransaction(true)
    // Convert platform fee (USD) to correct decimals (assuming 8 decimals as in the native example)
    const platformFee = platformFeeInDollars.times(10 ** 8)

    try {
      // Prepare call for `buyWithERC20` on the V4 sale contract
      const { request } = await simulateContract(wagmiConfig, {
        address: saleAddress,
        abi: getFlatPriceSaleABI('v4.0') as Abi,
        functionName: 'buyWithToken',
        chainId,
        args: [
          tokenAddress,
          BigInt(tokenValueInWei.toString()),
          userLimit,
          signatureExpiresAt,
          signature,
          platformFeeRecipient,
          platformFeeRecipient ? BigInt(platformFee.toString()) : BigInt(0),
        ],
        // Pay platform fee in native token:
        value: BigInt(platformFeeInWei.toString()),
      })

      // Warn if no platform fee recipient provided
      if (!platformFeeRecipient) {
        Sentry.captureMessage(
          `Buy with token called with null platform fee recipient for sale ${saleAddress} on chain ${chainId}.`,
        )
      }

      const hash = await writeContract(wagmiConfig, request)
      setTransactionHash(hash)
    } catch (err) {
      setSubmitTransactionError(err as Error)
    } finally {
      setIsPendingSubmitTransaction(false)
    }
  }

  const {
    data: transactionReceipt,
    isLoading: isLoadingTransactionReceipt,
    error: waitForTransactionReceiptError,
  } = useWaitForTransactionReceipt({
    hash: transactionHash ?? '0x',
    query: { enabled: transactionHash !== null },
  })

  return {
    buyWithToken,
    receipt: transactionReceipt,
    isPending:
      isPendingPurchaseSignature ||
      isPendingSubmitTransaction ||
      isLoadingTransactionReceipt,
    error:
      purchaseSignatureError ||
      waitForTransactionReceiptError ||
      submitTransactionError,
  }
}
