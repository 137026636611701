import { useToast, useTSWagmi } from '@contexts'
import { VESTING_TYPE } from '@enums'
import {
  getContinuousVestingMerkleABI,
  getTrancheVestingMerkleABI,
} from '@utils'
import { simulateContract, writeContract } from '@wagmi/core'
import { useState } from 'react'
import { TransactionExecutionError } from 'viem'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useUpdateDistributorMerkleRoot = () => {
  const { wagmiConfig } = useTSWagmi()
  const { showErrorToast } = useToast()
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const getABIForVestingType = (vestingType: VESTING_TYPE) => {
    switch (vestingType) {
      case VESTING_TYPE.CONTINUOUS:
      case VESTING_TYPE.NO_VESTING:
        return getContinuousVestingMerkleABI()
      case VESTING_TYPE.TRANCHE:
        return getTrancheVestingMerkleABI()
      default:
        throw new Error('Invalid vesting type')
    }
  }

  const write = async (
    vestingType: VESTING_TYPE,
    chainId: number,
    contractAddress: string,
    args: any[],
  ) => {
    setSubmitting(true)

    const abi = getABIForVestingType(vestingType)

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi: abi,
        functionName: 'setMerkleRoot',
        chainId,
        args,
      } as any)

      const writeContractResponse = await writeContract(wagmiConfig, request)
      setTransactionHash(writeContractResponse)
      return writeContractResponse
    } catch (e: any) {
      let msg: string
      if (e instanceof TransactionExecutionError) {
        msg = e.shortMessage
      } else {
        msg = e.message
      }

      if (msg === '') {
        msg = 'An error occurred while updating the distributor.'
      }

      showErrorToast({ description: msg })
      console.error(e)
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  }
}
