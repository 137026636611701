import {
  CheckboxInput,
  Col,
  EventVisibilityOptions,
  HelpText,
  InputGroup,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
} from '@components'
import { useAccount } from '@contexts'
import { EventWizardState } from '@customTypes'
import { useEventVisibilityOptions } from '@hooks'
import {
  getMaxEndDate,
  getMinEndDate,
  isAfter,
  isValidDate,
  validateEventGeneralFormSchema,
} from '@utils'
import { useEffect, useState } from 'react'

interface EventPageSetupGeneralProps {
  wizardState: EventWizardState
  updateWizardState: (state: EventWizardState) => void
  editMode: boolean
}

export const EventPageSetupGeneral = ({
  wizardState,
  updateWizardState,
  editMode,
}: EventPageSetupGeneralProps) => {
  const { account } = useAccount()

  const [formState, setFormState] = useState({
    name: wizardState.name || '',
    description: wizardState.description || '',
    startTime: wizardState.startTime ?? undefined,
    endTime: wizardState.endTime ?? undefined,
    alwaysOpen: !wizardState.endTime,
    useExperimentalContractFeatures:
      wizardState.useExperimentalContractFeatures ?? false,
  })

  const validStartTime =
    !formState.startTime || isValidDate(formState.startTime)
  const validEndTime =
    !formState.endTime ||
    (isValidDate(formState.endTime) &&
      !!formState.startTime &&
      isAfter(formState.endTime, formState.startTime))

  const {
    isEmailRequired,
    isWalletRequired,
    isPrivate,
    isPrivacyConfigurationValid,
    onIsPrivateToggled,
    uploadedFileUri,
    uploadedFileName,
    isPendingValidation,
    uploadedData,
    uploadFileErrorMessage,
    onEmailRequiredToggled,
    onWalletRequiredToggled,
    onPrivateEventParticipantsFileUploaded,
  } = useEventVisibilityOptions({
    eventPrivacyConfiguration: wizardState.privacyConfiguration,
    isPrivate: !!wizardState.privacyConfiguration,
  })

  const formValid: boolean =
    validateEventGeneralFormSchema(formState) &&
    validStartTime &&
    validEndTime &&
    (formState.alwaysOpen || !!formState.endTime) &&
    // If editMode, privacy configuration is hidden - editing privacy happens elsewhere
    // Otherwise, public is valid; if private, require private configuration
    (editMode || isPrivacyConfigurationValid)

  useEffect(() => {
    const newWizardState: typeof formState &
      Omit<EventWizardState, keyof typeof formState> = {
      ...wizardState,
      ...formState,
      endTime: formState.alwaysOpen ? '' : formState.endTime,
      name: formState.name,
      eventPageGeneralFormValid: formValid ?? undefined,
    }

    // Remove privacy configuration if the event is public
    if (!isPrivate) {
      delete newWizardState.privacyConfiguration
    }

    updateWizardState(newWizardState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, formValid, isPrivate])

  useEffect(() => {
    // Only set the privacy configuration if the form is valid
    // and it's actually a private event
    if (isPrivate && isPrivacyConfigurationValid && uploadedFileUri != null) {
      const newWizardState = {
        ...wizardState,
        privacyConfiguration: {
          isEmailRequired,
          isWalletRequired,
          participantsFileUri: uploadedFileUri,
        },
        eventPageGeneralFormValid: formValid && isPrivacyConfigurationValid,
      }

      // update the wizard state with the new private options
      updateWizardState(newWizardState)
    }
  }, [
    isPrivate,
    formValid,
    isPrivacyConfigurationValid,
    isEmailRequired,
    isWalletRequired,
    uploadedFileUri,
    updateWizardState,
    wizardState,
  ])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  return (
    <Stacked gap={5}>
      <Section>
        <Col>
          {/* Display Title */}
          <InputGroup
            label='Event Name'
            placeholder='Enter Here'
            value={formState.name}
            name='name'
            required
            maxLength={100}
            onChange={handleChange}
          />

          {/* Display subtitle */}
          <InputGroup
            label='Event Description'
            placeholder='Enter Here'
            value={formState.description}
            name='description'
            required
            maxLength={300}
            onChange={handleChange}
          />
        </Col>
      </Section>

      {/* EVENT TIMELINE SECTION */}
      <Section>
        <Stretched xgap={5} ygap={0}>
          {/* Start date/time */}
          <InputGroup
            className='w-1/2'
            type='datetime-local'
            label='Registration Start Date'
            value={formState.startTime}
            name='startTime'
            onChange={handleChange}
            valid={validStartTime}
            error={validStartTime ? '' : 'Invalid Start Date'}
          />

          {/* End date/time */}
          {formState.alwaysOpen ? (
            <div className='w-1/2' />
          ) : (
            <InputGroup
              className='start w-1/2'
              type='datetime-local'
              label='Registration End Date'
              value={formState.endTime}
              min={formState.startTime && getMinEndDate(formState.startTime)}
              max={getMaxEndDate(account?.timezone)}
              name='endTime'
              onChange={handleChange}
              valid={validEndTime}
              error={validEndTime ? '' : 'Invalid End Date'}
            />
          )}
        </Stretched>

        {/* Registration does not end checkbox */}
        <CheckboxInput
          className='cursor-pointer max-w-[fit-content]'
          onClick={(e) =>
            setFormState({
              ...formState,
              alwaysOpen: e.target.checked,
              ...(e.target.checked && { endTime: undefined }),
            })
          }
          name='alwaysOpen'
          checked={formState.alwaysOpen}
        >
          Registration does not end.
        </CheckboxInput>
        <HelpText>
          If you choose to leave the registration dates open and continue, by
          default your registration will open upon completing your event setup.
          You may always come back and edit your registration dates at a later
          time and set your official sale specific dates once you choose to
          deploy your sale on-chain.
        </HelpText>

        <CheckboxInput
          className='mt-5 cursor-pointer max-w-[fit-content]'
          name='useExperimentalContractFeatures'
          testId='useExperimentalContractFeatures'
          checked={formState.useExperimentalContractFeatures}
          onClick={(e) => {
            setFormState({
              ...formState,
              useExperimentalContractFeatures: e.target.checked,
            })
          }}
        >
          Use Experimental Contract Features
        </CheckboxInput>
      </Section>

      {/* Event Visibility Section */}
      {/* Because events cannot be toggled between private and public after creation,
          we only show the event visibility options if the event is being created.
          If the event is being edited, the visibility is locked, and editing the privacy
          list happens apart from editing the event general information. */}
      {!editMode && (
        <Section className='mt-12' gap={5}>
          <SectionTitle>Event Visibility</SectionTitle>
          <Text>
            By default this event will be visible to the public. If you would
            like to run a private event, you can toggle the visibility to
            private. For a private event, you will be required to upload a CSV
            file with a list of participants.
          </Text>
          <EventVisibilityOptions
            isPrivate={isPrivate}
            onIsPrivateToggled={onIsPrivateToggled}
            isEmailRequired={isEmailRequired}
            isWalletRequired={isWalletRequired}
            uploadedFileName={uploadedFileName}
            isPendingValidation={isPendingValidation}
            uploadedData={uploadedData}
            uploadFileErrorMessage={uploadFileErrorMessage}
            onEmailRequiredToggled={onEmailRequiredToggled}
            onWalletRequiredToggled={onWalletRequiredToggled}
            onPrivateEventParticipantsFileUploaded={
              onPrivateEventParticipantsFileUploaded
            }
          />
        </Section>
      )}
    </Stacked>
  )
}
