import { useToast, useTSWagmi } from '@contexts'
import { getAdvancedDistributorABI } from '@utils'
import { simulateContract, writeContract } from '@wagmi/core'
import { useState } from 'react'
import { TransactionExecutionError } from 'viem'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useUpdateDistributorUri = () => {
  const { wagmiConfig } = useTSWagmi()
  const { showErrorToast } = useToast()
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const write = async (chainId: any, contractAddress: any, args: any) => {
    setSubmitting(true)

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi: getAdvancedDistributorABI(),
        functionName: 'setUri',
        chainId,
        args,
      } as any)

      const writeContractResponse = await writeContract(wagmiConfig, request)
      setTransactionHash(writeContractResponse)
      return writeContractResponse
    } catch (e: any) {
      let msg: string
      if (e instanceof TransactionExecutionError) {
        msg = e.shortMessage
      } else {
        msg = e.message
      }

      if (msg === '') {
        msg = 'An error occurred while updating the distributor.'
      }

      showErrorToast({ description: msg })
      console.error(e)
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  }
}
