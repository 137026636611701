import { FailedCircleIcon, FrontArrowIcon, InProgressIcon } from '@components'
import { CheckableCircle } from '@newComponents'

interface EligibilityItemStatusProps {
  onClick?: () => void
  progressStatus: ProgressStatus
  title: string
  highlightColor?: HexColorCode
}

export const EligibilityItemStatus = ({
  title,
  progressStatus = 'incomplete',
  onClick,
  highlightColor,
}: EligibilityItemStatusProps) => {
  return (
    <button
      className={`flex min-h-[52px] w-full items-center py-2 px-3 gap-3
        border border-[#EAECF0] rounded-[4px]
        ${progressStatus === 'completed' && 'line-through bg-[#e8fdf2]'}
        ${
          ['incomplete', 'in-progress'].includes(progressStatus) &&
          `cursor-pointer border-primary-light hover:scale-[1.01] 
          hover:shadow-sm hover:border-primary-medium transition-all duration-[400ms]`
        },
        ${progressStatus === 'failed' && 'bg-[#fde8e8] border-[#f9d1d1]'}
      `}
      onClick={onClick}
    >
      {progressStatus === 'incomplete' && <CheckableCircle isChecked={false} />}
      {progressStatus === 'failed' && <FailedCircleIcon size={16} />}
      {progressStatus === 'in-progress' && (
        <InProgressIcon color={highlightColor} />
      )}
      {progressStatus === 'completed' && <CheckableCircle isChecked />}
      <div className='flex flex-row items-center p-0 gap-1 select-none'>
        {title}
      </div>
      {['incomplete', 'in-progress'].includes(progressStatus) && (
        <div className='ml-auto'>
          <FrontArrowIcon strokeWidth={0.25} color={highlightColor} />
        </div>
      )}
    </button>
  )
}
