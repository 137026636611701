import { useCreateMessage, useUploadKycDocuments } from '@apiServices'
import { ButtonRow, InputGroup } from '@components'
import { useAccount, useToast } from '@contexts'
import { FileUpload, LoaderButton } from '@newComponents'
import { ReactElement, useState } from 'react'

interface DocumentUploaderProps {
  onSetIsShowing: (isShowing: boolean) => void
  kycTier: KycTier
}

export const DocumentUploader = ({
  onSetIsShowing,
  kycTier,
}: DocumentUploaderProps): ReactElement => {
  const { account } = useAccount()

  const {
    mutateAsync: uploadKycDocuments,
    isPending: isPendingUploadKycDocuments,
  } = useUploadKycDocuments(kycTier)

  const { mutateAsync: createMessage, isPending: isPendingCreateMessage } =
    useCreateMessage()
  const { showSuccessToast, showErrorToast } = useToast()

  const [messageText, setMessageText] = useState<string>('')
  const [filesForUpload, setFilesForUpload] = useState<File[]>([])

  const handleSubmit = async () => {
    try {
      const documentIds = await uploadKycDocuments({
        files: filesForUpload,
        message: messageText || 'Additional documentation submitted for review',
      })

      await createMessage({
        account: {
          id: account.id,
          kycTiers: account.kyc?.tiers,
        },
        message: messageText || 'Additional documentation submitted for review',
        documents: documentIds.map((id) => ({ id })),
      })

      setMessageText('')
      setFilesForUpload([])
      onSetIsShowing(false)
      showSuccessToast({
        title: 'Documents received!',
        description:
          'If a response is required, you should expect one within 3-5 business days.',
      })
    } catch (e) {
      showErrorToast({
        description: 'Error submitting documents. Please try again.',
      })
    }
  }

  const isSubmitDisabled =
    isPendingCreateMessage ||
    isPendingUploadKycDocuments ||
    filesForUpload.length === 0

  return (
    <div className='mt-4'>
      <div>
        <p className={'mb-4'}>Additional documents</p>

        <FileUpload
          files={filesForUpload}
          onFileChange={setFilesForUpload}
          uploadStyle='dropzone'
        />

        <p className='text-xs text-neutral-light pt-2 pb-4'>
          * By providing information, you represent and warrant that it is true,
          complete, and correct.
        </p>

        <div>
          <p className='py-4'>Notes or comments (optional)</p>
          <InputGroup
            type='textarea'
            value={messageText}
            name='messageText'
            onChange={(e: any) => setMessageText(e.target.value)}
          />
        </div>
      </div>

      <ButtonRow place={'end'}>
        <button
          className='btn btn-outline-light'
          onClick={() => onSetIsShowing(false)}
          disabled={isPendingCreateMessage}
        >
          Cancel
        </button>

        <LoaderButton
          className='btn btn-primary'
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          isLoading={isPendingCreateMessage}
          text='Submit'
          loadingText='Submitting Docs...'
        />
      </ButtonRow>
    </div>
  )
}
