import { useGetPurchaseSignature } from '@apiServices'
import { useTSWagmi } from '@contexts'
import * as Sentry from '@sentry/react'
import { getFlatPriceSaleABI } from '@utils'
import { simulateContract, writeContract } from '@wagmi/core'
import BigNumber from 'bignumber.js'
import { useState } from 'react'
import { Abi } from 'viem'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useBuyWithNative = (chainId: ChainId, saleAddress: EvmAddress) => {
  const { wagmiConfig } = useTSWagmi()

  const [isPendingSubmitTransaction, setIsPendingSubmitTransaction] =
    useState<boolean>(false)
  const [submitTransactionError, setSubmitTransactionError] =
    useState<Maybe<Error>>(null)
  const [transactionHash, setTransactionHash] =
    useState<Maybe<EvmAddress>>(null)

  const {
    data: purchaseSignatureData,
    isPending: isPendingPurchaseSignature,
    error: purchaseSignatureError,
  } = useGetPurchaseSignature(saleAddress)

  const buyWithNative = async (
    purchaseValueInWei: BigNumber,
    platformFeeRecipient: Maybe<EvmAddress>,
    platformFeeInDollars: CurrencyValue,
    platformFeeInWei: BigNumber,
  ) => {
    if (!purchaseSignatureData) {
      return
    }

    const {
      userLimit,
      expiresAt: signatureExpiresAt,
      signature,
    } = purchaseSignatureData

    setIsPendingSubmitTransaction(true)

    const totalPurchaseCost = purchaseValueInWei
      .plus(platformFeeInWei)
      .decimalPlaces(0)

    // Sale platform fee is USD with 8 decimals
    const platformFee = platformFeeInDollars.value.times(10 ** 8)

    // Get second argument type of simulateContract
    const simulateParameters = {
      address: saleAddress,
      functionName: 'buyWithNative',
      chainId,
      args: [
        userLimit,
        signatureExpiresAt,
        signature,
        platformFeeRecipient,
        // If platform fee recipient is not defined for the current chain,
        // it is likely an error. But to handle, just set fee to 0.
        platformFeeRecipient !== null
          ? BigInt(platformFee.toString())
          : BigInt(0),
      ],
      value: BigInt(totalPurchaseCost.toString()),
    }

    try {
      const response = await simulateContract(wagmiConfig, {
        ...simulateParameters,
        abi: getFlatPriceSaleABI('v4.0') as Abi,
      })

      // Capture error if platform fee recipient is null
      if (platformFeeRecipient === null) {
        const msg = {
          chainId: chainId,
          saleId: saleAddress,
        }
        Sentry.captureMessage(
          `Buy with native purchase occurred with null platform fee recipient. Context: ${JSON.stringify(msg)}`,
        )
      }

      const transactionHash = await writeContract(wagmiConfig, response.request)

      setTransactionHash(transactionHash)
    } catch (e) {
      setSubmitTransactionError(e as Error)
    } finally {
      setIsPendingSubmitTransaction(false)
    }
  }

  const {
    data: transactionReceipt = null,
    isLoading: isLoadingTransactionReceipt,
    error: waitForTransactionReceiptError,
  } = useWaitForTransactionReceipt({
    hash: transactionHash ?? '0x',
    query: {
      enabled: transactionHash !== null,
    },
  })

  return {
    buyWithNative,
    receipt: transactionReceipt,
    isPending:
      isPendingPurchaseSignature ||
      isPendingSubmitTransaction ||
      isLoadingTransactionReceipt,
    error:
      purchaseSignatureError ||
      waitForTransactionReceiptError ||
      submitTransactionError,
  }
}
