import { useGetJoinStatusForEvent, useRequestAccess } from '@apiServices'
import { Button, LoadingIndicator } from '@components'
import { useReferralCode } from '@hooks'
import { Card } from '@newComponents'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { DocumentEligibilityStatus } from './document-eligibility-status'
import { EligibilityItemStatus } from './eligibility-item-status'
import { KycEligibilityStatus } from './kyc-eligibility-status'
import { LocationEligibilityStatus } from './location-eligibility-status'
import { PolkadotWalletEligibilityStatus } from './polkadot-wallet-eligibility-status'

type EventEligibilityCardProps = {
  className?: string
  eventId: ID
  kycRequirement: KycRestrictionType
  isPolkadotWalletRequired: boolean
  highlightColor: HexColorCode
  onMetEligibilityRequirements: () => void
  isRegistrationOpen: boolean
}

export const EventEligibilityCard = ({
  eventId,
  kycRequirement,
  isPolkadotWalletRequired,
  highlightColor,
  onMetEligibilityRequirements,
  isRegistrationOpen,
  className = '',
}: EventEligibilityCardProps) => {
  const {
    mutate: requestAccess,
    isSuccess: requestAccessSuccess,
    isPending: isRequestAccessPending,
  } = useRequestAccess()

  const referralCode = useReferralCode()

  const {
    data: hasJoinedEvent,
    isPending: isJoinStatusPending,
    refetch: refetchUserJoinStatus,
  } = useGetJoinStatusForEvent(eventId)

  useEffect(() => {
    if (requestAccessSuccess) {
      refetchUserJoinStatus()
    }
  }, [requestAccessSuccess])

  const [locationRequirementMet, setLocationRequirementMet] = useState(false)

  const eventHasKycRequirement =
    ['kyc_required', 'kyc_and_accredited_investor_required'].indexOf(
      kycRequirement,
    ) !== -1

  const [polkadotWalletRequirementMet, setPolkadotWalletRequirementMet] =
    useState(!isPolkadotWalletRequired)

  // If no KYC requirement, set to true by default
  const [kycRequirementMet, setKycRequirementMet] = useState(
    !eventHasKycRequirement,
  )

  const [documentRequirementMet, setDocumentRequirementMet] = useState(false)

  const onMetLocationRequirement = () => {
    setLocationRequirementMet(true)
  }

  const onMetPolkadotWalletRequirement = () => {
    setPolkadotWalletRequirementMet(true)
  }

  const onMetKycRequirement = () => {
    setKycRequirementMet(true)
  }

  const onMetDocumentRequirement = () => {
    setDocumentRequirementMet(true)
  }

  useEffect(() => {
    if (
      hasJoinedEvent &&
      locationRequirementMet &&
      kycRequirementMet &&
      polkadotWalletRequirementMet &&
      documentRequirementMet
    ) {
      onMetEligibilityRequirements()
    }
  }, [
    hasJoinedEvent,
    locationRequirementMet,
    kycRequirementMet,
    polkadotWalletRequirementMet,
    documentRequirementMet,
    onMetEligibilityRequirements,
  ])

  return (
    <Card
      className={twMerge(className, !isRegistrationOpen && 'hidden')}
      title='Join the Event'
    >
      <div className='flex flex-col items-stretch'>
        <div className={`flex flex-col gap-3 relative`}>
          {isJoinStatusPending && (
            <div className='relative min-w-72 min-h-64'>
              <LoadingIndicator className='absolute inset-0' />
            </div>
          )}
          {!isJoinStatusPending && !hasJoinedEvent && (
            <>
              <div className='absolute -inset-y-2 -inset-x-6 backdrop-blur-md flex justify-center items-center'>
                <Button
                  onClick={() =>
                    requestAccess({ eventId: eventId, referralCode })
                  }
                  className='self-center'
                  disabled={isRequestAccessPending}
                >
                  {isRequestAccessPending ? 'Pending...' : 'Request Access'}
                </Button>
              </div>
              <div className='flex flex-col gap-3'>
                <p>
                  To participate in this event, you must meet the following
                  requirements.
                </p>
                <EligibilityItemStatus
                  progressStatus='in-progress'
                  title='Requirement 1'
                />
                <EligibilityItemStatus
                  progressStatus='completed'
                  title='Requirement 2'
                />
                <EligibilityItemStatus
                  progressStatus='in-progress'
                  title='Requirement 3'
                />
                <EligibilityItemStatus
                  progressStatus='incomplete'
                  title='Requirement 4'
                />
              </div>
            </>
          )}
          {hasJoinedEvent && (
            <div className='flex flex-col gap-3'>
              <span>
                To participate in this event, you must meet the following
                requirements.
              </span>

              <LocationEligibilityStatus
                eventId={eventId}
                highlightColor={highlightColor}
                onMetRequirement={onMetLocationRequirement}
              />

              {eventHasKycRequirement && (
                <KycEligibilityStatus
                  kycRequirement={kycRequirement}
                  highlightColor={highlightColor}
                  onMetRequirement={onMetKycRequirement}
                />
              )}

              {isPolkadotWalletRequired && (
                <PolkadotWalletEligibilityStatus
                  highlightColor={highlightColor}
                  onMetRequirement={onMetPolkadotWalletRequirement}
                />
              )}
              {hasJoinedEvent && (
                <DocumentEligibilityStatus
                  eventId={eventId}
                  onMetRequirement={onMetDocumentRequirement}
                  hasMetRequirement={documentRequirementMet}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}
