import { useWebApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'

export const useGetMyPolkadotWallets = () => {
  const fetchWithClient = useWebApiClient()

  return useQuery<{ polkadotWalletAddress: string; isVerified: boolean }[]>({
    queryKey: ['wallets/polkadot'],
    queryFn: async () => {
      return fetchWithClient('wallets/polkadot')
    },
  })
}
