import {
  arbitrum,
  avalanche,
  avalancheFuji,
  base,
  baseSepolia,
  bsc,
  bscTestnet,
  celoAlfajores,
  gnosis,
  mainnet,
  optimism,
  optimismSepolia,
  polygon,
  polygonAmoy,
  polygonMumbai,
  scroll,
  scrollSepolia,
  sepolia,
  zkSync,
  zkSyncSepoliaTestnet,
  anvil,
} from 'wagmi/chains'

export const wagmiTransportUrls = {
  [mainnet.id]: [
    `https://eth-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_MAINNET_KEY}`,
    `https://mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
  ],
  [sepolia.id]: [
    'https://eth-sepolia.g.alchemy.com/v2/InkiwNpsvN7H3e4U66aeDRVYuGHLJwyQ',
  ],
  [polygon.id]: [
    'https://polygon-mainnet.g.alchemy.com/v2/GU2BlS-yc2EwBBBoVDkMOJQ9fwuJ2HFx',
  ],
  [polygonMumbai.id]: [
    'https://polygon-mumbai.g.alchemy.com/v2/xXyY_554uXE0AtP3HAuNPeYlioLZYusX',
  ],
  [polygonAmoy.id]: [
    'https://polygon-amoy.g.alchemy.com/v2/GU2BlS-yc2EwBBBoVDkMOJQ9fwuJ2HFx',
  ],
  [optimism.id]: [
    'https://opt-mainnet.g.alchemy.com/v2/a8LjsXrGHixpkkjyfek2MoKW71Uzds1j',
  ],
  [optimismSepolia.id]: [
    'https://opt-sepolia.g.alchemy.com/v2/ofpynGFfJOQpgDiaCe71c13mYREnYd8L',
  ],
  [arbitrum.id]: [
    'https://arb-mainnet.g.alchemy.com/v2/EG5cqnTERCjrn6aAfw8tQ-ldKeQ6x4Nf',
  ],
  [base.id]: [
    'https://base-mainnet.g.alchemy.com/v2/-o6mRr3FmmpIt3_d78AlveAo_dKJmzgL',
  ],
  [baseSepolia.id]: [
    'https://base-sepolia.g.alchemy.com/v2/PgLHQTZzLYXfi1U2ljbHf_VFLskx5AX8',
  ],
  [bsc.id]: ['https://bsc-dataseed1.binance.org/'],
  [bscTestnet.id]: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
  [avalanche.id]: ['https://api.avax.network/ext/bc/C/rpc'],
  [avalancheFuji.id]: ['https://api.avax-test.network/ext/bc/C/rpc'],
  [gnosis.id]: ['https://rpc.gnosischain.com'],
  [celoAlfajores.id]: ['https://alfajores-forno.celo-testnet.org'],
  [zkSync.id]: [
    `https://zksync-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_ZKSYNC_KEY}`,
  ],
  [zkSyncSepoliaTestnet.id]: [
    `https://zksync-sepolia.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_ZKSYNC_SEPOLIA_KEY}`,
  ],
  [scroll.id]: [
    `https://scroll-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_MAINNET_KEY}`,
  ],
  [scrollSepolia.id]: [
    `https://scroll-sepolia.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_MAINNET_KEY}`,
  ],
  [anvil.id]: ['http://localhost:8545'],
}
