import { usePublicApiClient } from '@apiClients'
import {
  getProjectPrivacyDocumentsSync,
  useGetProject,
  useGetProjectTheme,
} from '@apiServices'
import { SmartContractNetworkSelect } from '@components'
import { DEFAULT_PRIMARY_COLOR } from '@constants'
import { useAuth, useTSWagmi } from '@contexts'
import {
  RainbowKitAuthenticationProvider,
  RainbowKitProvider,
  createAuthenticationAdapter,
  lightTheme,
} from '@rainbow-me/rainbowkit'
import { getSignStatement } from '@utils'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { SiweMessage } from 'siwe'
import { useAccount as useWagmiAccount } from 'wagmi'

declare const window: Window & {
  location: Location & {
    host: string
    origin: string
  }
}

interface RainbowKitProviderProps {
  children?: ReactNode
}

export const RainbowkitProvider: FC<RainbowKitProviderProps> = ({
  children,
}) => {
  const { isAuthenticated, authenticate, disconnect } = useAuth()
  const { defaultChain } = useTSWagmi()
  const { data: project } = useGetProject()
  const { data: projectTheme } = useGetProjectTheme()
  const publicApiClient = usePublicApiClient()

  console.log(`INFO: RainbowkitProvider runs`)

  console.log(`INFO: RainbowkitProvider runs`)

  const authenticationAdapter = useMemo(() => {
    // console.log('creating new authentication adapter');

    return createAuthenticationAdapter({
      /**
       * Generate a dummy nonce as we won't be using this value anyway.  We will
       * get a nonce from the backend in the createMessage function below.
       */
      getNonce: async () => {
        console.log('INFO: RainbowkitProvider-getNonce() called')
        return 'fake'
      },

      createMessage: ({ nonce, address: messageAddress, chainId }) => {
        console.log('INFO: RainbowkitProvider-createMessage() called')
        // console.log(
        //   'adapter: creating message for wallet address',
        //   messageAddress,
        // );

        // nonce = getNonceSync(messageAddress);

        const issuer = project?.name
        const projectId = project?.id ? `${project.id}` : undefined
        const documents = getProjectPrivacyDocumentsSync(projectId)

        try {
          return new SiweMessage({
            domain: window.location.host,
            address: messageAddress,
            statement: getSignStatement(
              issuer,
              documents,
              window.location.host,
            ),
            uri: window.location.origin,
            version: '1',
            chainId: chainId,
            nonce: nonce,
          })
        } catch (error) {
          console.error('Failed to create SIWE message:', error)
          throw error
        }
      },

      getMessageBody: ({ message }) => {
        console.log('INFO: RainbowkitProvider-getMessageBody() called')
        try {
          // console.log('adapter: preparing message');
          return message.prepareMessage()
        } catch (error) {
          console.error('Failed to prepare SIWE message:', error)
          throw error
        }
      },

      verify: async ({ message, signature }) => {
        console.log('INFO: RainbowkitProvider-verify() called')
        // console.log('adapter: verifying wallet address', message.address);

        const data = {
          walletAddress: message.address,
          signature,
          message,
          nonce: message.nonce,
        }

        try {
          const response = await publicApiClient('wallets/connect', {
            data: data,
          })

          authenticate({ ...response, walletAddress: message.address })

          console.log(
            'adapter: authentication success for wallet address',
            message.address,
          )
          return Boolean(true)
        } catch (e) {
          console.log({ e })
        }

        return Boolean(false)
      },

      signOut: async () => {
        console.log('INFO: RainbowkitProvider-signOut() called')
        // console.log('adapter: signing out...');
        disconnect()
      },
    })
  }, [])

  const { isDisconnected } = useWagmiAccount()
  const [disconnectedTimestamp, setDisconnectedTimestamp] = useState<number>(
    Date.now(),
  )

  useEffect(() => {
    if (isDisconnected) {
      setDisconnectedTimestamp(Date.now())
    }
  }, [isDisconnected])

  return (
    <>
      <RainbowKitAuthenticationProvider
        adapter={authenticationAdapter}
        status={isAuthenticated ? 'authenticated' : 'unauthenticated'}
      >
        <RainbowKitProvider
          key={`${disconnectedTimestamp}`}
          initialChain={defaultChain}
          modalSize={'compact'}
          theme={lightTheme({
            accentColor: projectTheme?.colors.primary || DEFAULT_PRIMARY_COLOR,
            accentColorForeground: 'white',
            borderRadius: 'medium',
          })}
          appInfo={{
            appName: 'Tokensoft',
            learnMoreUrl: 'https://www.tokensoft.io',
            disclaimer: ({ Text, Link }) => (
              <Text>
                <div className={'flex justify-center mb-1'}>
                  <SmartContractNetworkSelect />
                </div>
                <span>
                  By connecting your wallet, you agree to the{' '}
                  <Link href='https://www.tokensoft.io/terms'>
                    Terms and Conditions
                  </Link>
                  ,{' '}
                  <Link href='https://www.tokensoft.io/privacy'>
                    Privacy Policy
                  </Link>
                  , and{' '}
                  <Link href='https://www.tokensoft.io/cookies'>
                    Cookie Policy
                  </Link>
                  .
                </span>
              </Text>
            ),
          }}
        >
          {children}
        </RainbowKitProvider>
      </RainbowKitAuthenticationProvider>
    </>
  )
}
