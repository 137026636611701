import { Button, ClaimInitializeIcon, Text } from '@components'
import { useSubmitClaim } from '@utils'
import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import {
  Distributor,
  DistributorMerkleTreeLeaf,
  Erc20Token,
  TSEvent,
} from 'tokensoft-shared-types'

interface ClaimFlow {
  event: TSEvent
  distributor: Distributor
  chainId: number
  distributorAddress: EvmAddress
  token: Erc20Token
  claimAmount: BigNumber
  merkleLeaf: DistributorMerkleTreeLeaf
  onClaimSuccess: (receipt: any) => void
}

export const ClaimFlow = ({
  event,
  distributor,
  chainId,
  distributorAddress,
  token,
  claimAmount,
  merkleLeaf,
  onClaimSuccess,
}: ClaimFlow) => {
  const {
    write: submitClaim,
    isSubmitting: isClaiming,
    ...waitForTransactionResponse
  } = useSubmitClaim({ event, distributor })

  useEffect(() => {
    if (waitForTransactionResponse.isSuccess) {
      onClaimSuccess(waitForTransactionResponse.data)
    }
  }, [waitForTransactionResponse])

  const handleClaim = () => {
    // [proofIndex, beneficiary, amount, merkleProof]
    submitClaim(chainId, distributorAddress, [
      merkleLeaf.index,
      merkleLeaf.beneficiary,
      merkleLeaf.amount,
      merkleLeaf.merkleProof,
    ])
  }

  const toFixedWithoutTrailingZeros = (number: BigNumber) => {
    return parseFloat(number.toFixed(6)).toString()
  }

  return (
    <div className='flex flex-col items-center w-full p-8 gap-4'>
      <ClaimInitializeIcon />
      <Text className='text-2xl font-semibold text-black'>
        Claim {token.symbol} ({token.name})
      </Text>
      <div className='flex gap-2 items-center'>
        <Text className=''>Amount:</Text>
        <Text className='text-xl font-bold text-black'>
          {toFixedWithoutTrailingZeros(claimAmount)} {token.symbol}
        </Text>
      </div>
      {waitForTransactionResponse.isLoading && (
        <p className='max-w-96 text-center'>
          You have successfully submitted your claim. Please wait for the
          transaction to be confirmed on chain. This may take a minute.
        </p>
      )}
      <Button
        onClick={handleClaim}
        disabled={isClaiming || waitForTransactionResponse.isLoading}
      >
        {isClaiming || waitForTransactionResponse.isLoading ? (
          <div className='flex flex-row justify-center items-center'>
            <div className='animate-spin'>
              <AiOutlineLoading3Quarters size={16} />
            </div>
            <span className='pl-2'>Claiming...</span>
          </div>
        ) : (
          <span>Claim</span>
        )}
      </Button>
    </div>
  )
}
