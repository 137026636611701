import { useAdminApiClient, useEventApiClient } from '@apiClients'
import { formatDistributor } from '@apiServices'
import { useAuth } from '@contexts'
import {
  EventIPAddressEligibility,
  EventPrivacyConfiguration,
} from '@customTypes'
import { EVENT_STATUS } from '@enums'
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query'
import { getUtcNow, gt, isAfter, isBefore, min, mult, sub } from '@utils'
import axios from 'axios'
import dayjs from 'dayjs'

export const useEventEligibility = (eventId: string, enabled: boolean) => {
  const client = useEventApiClient()
  const {
    user: { walletAddress },
  } = useAuth()

  return useQuery({
    enabled: !!(eventId && enabled),
    queryKey: ['eventId', eventId],
    queryFn: () =>
      client(
        `events/${eventId}/eligibility?cacheId=${eventId}_${walletAddress}`,
      ),
  })
}

export const useAdminGetEvent = (eventId: any) => {
  const client = useEventApiClient()
  const result = useQuery({
    enabled: !!eventId,
    queryKey: ['event', eventId],
    queryFn: () => client(`events/${eventId}`),
  })

  return {
    ...result,
    event: result?.data ? formatEvent(result.data) : undefined,
    loading: result?.isLoading,
    refresh: result?.refetch,
  }
}

export const useGetEventSummary = (eventId: any) => {
  const client = useEventApiClient()
  const result = useQuery({
    enabled: !!eventId,
    queryKey: ['eventSummary', eventId],
    queryFn: () => client(`events/${eventId}/summary`),
  })

  return {
    ...result,
    eventSummary: result?.data?.eventSummary,
    loading: result?.isLoading,
    refresh: result?.refetch,
  }
}

export const useGetMeEvent = (eventId: number): UseQueryResult<any> => {
  const client = useEventApiClient()
  return useQuery({
    enabled: !!eventId,
    queryKey: ['eventMe', eventId],
    queryFn: async () => {
      try {
        return client(`events/${eventId}/me`)
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 404) {
            return null
          }

          if (e.response?.status === 403) {
            throw new Error('You do not have access to this event.')
          }
        }

        throw e
      }
    },
  })
}

export const formatEvent = (event: any) => {
  let formattedEvent: any = {
    ...event,
  }

  formattedEvent.status = getEventStatus(event)

  formattedEvent.isRegistrationOpen =
    event?.registrationStartTime &&
    isAfter(getUtcNow(), event.registrationStartTime) &&
    (!event?.registrationEndTime ||
      isBefore(getUtcNow(), event.registrationEndTime))

  if (event.distributor) {
    const distributor = formatDistributor(event.distributor)
    formattedEvent = {
      ...formattedEvent,
      distributor: distributor,
    }
  }

  return formattedEvent
}

export const getEventStatus = (event: any): EVENT_STATUS => {
  const { startTime, endTime } = event

  let status = EVENT_STATUS.COMPLETED
  const now = dayjs()

  // Events have registrationStartTime, registrationEndTime, startTime, and endTime
  // For identity events, startTime and endTime are the same as their registration
  // equivalents. For other events (sale, distribution), registrationStartTime and
  // registrationEndTime are the registration period, and startTime and endTime are
  // the actual event period.
  if (!startTime && !endTime) {
    return EVENT_STATUS.UPCOMING
  }

  if (now.isBefore(dayjs(startTime))) {
    status = EVENT_STATUS.UPCOMING
  } else {
    status = EVENT_STATUS.CURRENT
    if (now.isAfter(dayjs(endTime))) {
      status = EVENT_STATUS.COMPLETED
    }
  }

  if (isSaleCapMet(event.sale)) {
    status = EVENT_STATUS.COMPLETED
  }

  return status
}

const isSaleCapMet = (sale: any) => {
  if (!sale?.id) {
    return false
  }

  const limit = sub(
    sale.saleMaximum,
    min(mult(5, sale.userMaximum), mult(0.005, sale.saleMaximum)),
  )
  return gt(sale.purchaseTotal, limit)
}

export const useUpdateEvent = () => {
  const client = useAdminApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/${data.id}`, {
        method: 'put',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}

export const useCreateReferralEvents = () => {
  const client = useEventApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/${data.event_id}/referral-codes`, {
        method: 'post',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}

export const useUpdateReferralEvents = () => {
  const client = useEventApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/${data.event_id}/referral-codes/${data.id}`, {
        method: 'put',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}

export const useCreateEvent = () => {
  const client = useAdminApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events`, {
        method: 'post',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}

export const useGetUsersEvent = () => {
  const client = useAdminApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/users/search`, {
        method: 'post',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}

export const useDeleteUserEvent = () => {
  const client = useAdminApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/${data.eventId}/users/${data.userId}`, {
        method: 'delete',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}

export const useRequestAccess = () => {
  const client = useEventApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (args: { eventId: number; referralCode: Maybe<string> }) => {
      const body: { referralCode?: string } = {}
      if (args.referralCode !== null) {
        body.referralCode = args.referralCode
      }
      return client(`events/${args.eventId}/request-access`, {
        method: 'post',
        data: body,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}

export const useGetUserEventsByAuthIdAndProjectId = () => {
  const client = useAdminApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`projects/${data.projectId}/users/${data.authId}/events`, {
        method: 'get',
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}

export interface EventParticipantsValidationRequestBody {
  participantsFileUri: string
  isWalletRequired: boolean
  isEmailRequired: boolean
}

export const useValidatePrivateEventParticipants = () => {
  const client = useEventApiClient()

  return useMutation({
    mutationFn: (data: EventParticipantsValidationRequestBody) => {
      return client(`events/participants/validate`, {
        method: 'post',
        data,
      })
    },
  })
}

export const useGetEventPrivacyConfiguration = (eventId: number): any => {
  const client = useEventApiClient()

  return useQuery({
    enabled: !!eventId,
    queryKey: ['eventPrivacyConfiguration', eventId],
    queryFn: () => client(`events/${eventId}/privacy`),
  })
}

interface UpdateEventPrivacyConfigurationRequest {
  eventId: number
  privacyConfiguration: EventPrivacyConfiguration
}

export const useUpdateEventPrivacyConfiguration = () => {
  const client = useEventApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (variables: UpdateEventPrivacyConfigurationRequest) => {
      const { eventId, privacyConfiguration } = variables
      return client(`events/${eventId}/privacy`, {
        method: 'put',
        data: privacyConfiguration,
      })
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['eventPrivacyConfiguration'],
      }),
  })
}

export const useGetEventIPAddressEligibility = (
  eventId?: number,
): UseQueryResult<EventIPAddressEligibility> => {
  const client = useEventApiClient()

  return useQuery<EventIPAddressEligibility>({
    enabled: !!eventId,
    queryKey: ['eventIPAddressEligibility', eventId],
    queryFn: () => client(`events/${eventId}/eligibility/ip-address`),
    // Always fetch the latest data
    staleTime: 0,
  })
}
