import { InfoCircleIcon, Text } from '@components'
import { FormattedCurrency } from '@newComponents'
import BigNumber from 'bignumber.js'
import { Sale } from 'tokensoft-shared-types'

type Props = {
  sale: Sale
  userWalletPurchaseTotal: CurrencyValue
}

interface PurchaseLimitsSection {
  title: string
  limits: { limitTitle: string; limitValue: string | JSX.Element }[]
}
const PurchaseLimitsSection = ({ title, limits }: PurchaseLimitsSection) => {
  return (
    <div className='p-4 rounded-sm'>
      <div className='flex justify-start gap-2'>
        <InfoCircleIcon />
        <div className='flex flex-col gap-2'>
          <p className='font-medium text-gray-700'>{title}</p>
          <div className='text-gray-600'>
            {limits.map((limit) => (
              <p key={limit.limitTitle}>
                {limit.limitTitle}: {limit.limitValue}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export const PurchaseLimits = ({ sale, userWalletPurchaseTotal }: Props) => {
  const minQuantity = {
    value: sale.minimumPurchase.value.div(sale.token.price.value),
    symbol: sale.token.symbol,
  }

  const maxQuantity = {
    value: sale.maximumPurchase.value.div(sale.token.price.value),
    symbol: sale.token.symbol,
  }

  const userPurchasedQuantity = {
    value: userWalletPurchaseTotal.value.div(sale.token.price.value),
    symbol: sale.token.symbol,
  }

  const userPurchaseValueRemaining = sale.maximumPurchase.value.minus(
    userWalletPurchaseTotal.value,
  )
  const userPurchasedQuantityRemaining = userPurchaseValueRemaining.div(
    sale.token.price.value,
  )

  const pluralize = (quantity: BigNumber) => (quantity.eq(1) ? '' : 's')

  return (
    <>
      <Text className='text-2xl text-[#111827] font-medium'>
        Purchase {sale.token.symbol}
        {sale.assetType === 'node' ? ' Node License' : ''}
      </Text>
      <div className='bg-blue-50 px-2 py-1 rounded-md flex flex-col sm:flex-row gap-4'>
        <PurchaseLimitsSection
          title='Purchase Limits'
          limits={[
            {
              limitTitle:
                sale.assetType === 'node' ? 'Min Quantity' : 'Min Amount',
              limitValue:
                sale.assetType === 'node' ? (
                  <>
                    <FormattedCurrency
                      currencyValue={minQuantity}
                      displayDecimals={0}
                    />{' '}
                    Node License{pluralize(minQuantity.value)}
                  </>
                ) : (
                  <FormattedCurrency
                    currencyValue={sale.minimumPurchase}
                    displayDecimals={0}
                  />
                ),
            },
            {
              limitTitle:
                sale.assetType === 'node' ? 'Max Quantity' : 'Max Amount',
              limitValue:
                sale.assetType === 'node' ? (
                  <>
                    <FormattedCurrency
                      currencyValue={maxQuantity}
                      displayDecimals={0}
                    />{' '}
                    Node License{pluralize(maxQuantity.value)}
                  </>
                ) : (
                  <FormattedCurrency
                    currencyValue={sale.maximumPurchase}
                    displayDecimals={0}
                  />
                ),
            },
          ]}
        />
        {!userWalletPurchaseTotal.value.eq(0) && (
          <>
            <div className='h-auto w-[1px] bg-gray-300 hidden sm:block' />
            <PurchaseLimitsSection
              title='Your Purchases'
              limits={[
                {
                  limitTitle: 'Purchased',
                  limitValue:
                    sale.assetType === 'node' ? (
                      <>
                        <FormattedCurrency
                          currencyValue={userPurchasedQuantity}
                          displayDecimals={0}
                        />{' '}
                        Node License{pluralize(userPurchasedQuantity.value)}
                      </>
                    ) : (
                      <FormattedCurrency
                        currencyValue={userWalletPurchaseTotal}
                        displayDecimals={0}
                      />
                    ),
                },
                {
                  limitTitle: 'Remaining',
                  limitValue:
                    sale.assetType === 'node' ? (
                      <>
                        <FormattedCurrency
                          currencyValue={{
                            value: userPurchasedQuantityRemaining,
                            symbol: sale.token.symbol,
                          }}
                          displayDecimals={0}
                        />{' '}
                        Node License{pluralize(userPurchasedQuantityRemaining)}
                      </>
                    ) : (
                      <FormattedCurrency
                        currencyValue={{
                          value: userPurchaseValueRemaining,
                          symbol: 'USD',
                        }}
                        displayDecimals={0}
                      />
                    ),
                },
              ]}
            />
          </>
        )}
      </div>

      <div className='bg-[#fffbe9] p-3 rounded-sm text-center text-xl font-regular tracking-wide text-[#475467]'>
        <FormattedCurrency
          currencyValue={sale.token.price}
          displayDecimals={sale.assetType === 'node' ? 0 : 2}
        />{' '}
        = 1 {sale.token.symbol}
        {sale.assetType === 'node' ? ' Node License' : ''}
      </div>
    </>
  )
}
