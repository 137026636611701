import { Card, Col, InputGroup, MultiSelect, Stretched } from '@components'
import { STATES_AND_TERRITORIES } from '@constants'
import { AccountDetails } from '@customTypes'
import {
  countryList,
  getMaxDob,
  useCountryCode,
  validateBasicInformation,
  validateEmail,
  validatePhoneNumber,
} from '@utils'
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'

type BasicInformationPanelProps = {
  accountDetails: AccountDetails
  onChange: (details: AccountDetails) => void
  onValidChange?: (valid: boolean) => void
}

export const BasicInformationPanel: FC<BasicInformationPanelProps> = ({
  accountDetails,
  onChange,
  onValidChange,
}) => {
  const countryOptions = useMemo(() => countryList().getData(), [])
  const stateOptions = useMemo(() => STATES_AND_TERRITORIES, [])

  const [selectedCountry, setSelectedCountry] = useState<Maybe<any>>(null)
  const [selectedState, setSelectedState] = useState<Maybe<any>>(null)
  const [formValid, setFormValid] = useState<boolean>(false)
  const { countryCode } = useCountryCode()

  useEffect(() => {
    if (accountDetails?.state) {
      setSelectedState(
        stateOptions.find(
          (options) => options.value === accountDetails?.state,
        ) || null,
      )
    }
  }, [accountDetails])

  useEffect(() => {
    if (countryCode) {
      setSelectedCountry(
        countryOptions.find((options) => options.value === countryCode) || null,
      )
      onChange({ ...accountDetails, ['country']: countryCode })
    }
  }, [countryCode])

  useEffect(() => {
    const valid = validateBasicInformation({
      ...accountDetails,
    })
    setFormValid(valid)
    onValidChange && onValidChange(valid)
  }, [accountDetails])

  const emailValid = validateEmail(accountDetails?.email)
  const phoneNumberValid = validatePhoneNumber(accountDetails?.phoneNumber)

  // Propagate the change upward by providing the new accountDetails object.
  const handleChange = (name: any) => (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...accountDetails, [name]: e.target.value })
  }

  const handleStateChange = (state: any) => {
    setSelectedState(state)
    onChange({ ...accountDetails, ['state']: state.value })
  }

  return (
    <Col id='basic-information'>
      <Card title='Contact Information' subtitle='Tell us about yourself.'>
        <form>
          <Stretched gap={5}>
            <InputGroup
              label='First name'
              value={accountDetails?.firstName}
              name='firstName'
              required={true}
              onChange={handleChange('firstName')}
            />
            <InputGroup
              value={accountDetails?.middleName}
              label='Middle name'
              name='middleName'
              required={false}
              onChange={handleChange('middleName')}
            />
            <InputGroup
              label='Last name'
              value={accountDetails?.lastName}
              name='lastName'
              required={true}
              onChange={handleChange('lastName')}
            />
          </Stretched>

          <InputGroup
            label='Date of birth'
            name='dob'
            type='date'
            value={accountDetails?.dob || ''}
            required={true}
            onChange={handleChange('dob')}
            max={getMaxDob()}
          />

          <InputGroup
            label='Phone number'
            name='phoneNumber'
            value={accountDetails?.phoneNumber}
            required={false}
            onChange={handleChange('phoneNumber')}
            error={phoneNumberValid ? null : 'Invalid phone number'}
          />

          <InputGroup
            label='Email address'
            name='email'
            value={accountDetails?.email}
            required={true}
            onChange={handleChange('email')}
            error={emailValid ? null : 'Invalid email'}
          />

          <InputGroup
            label='Country'
            value={selectedCountry?.label}
            readonly
            required
          />

          <InputGroup
            label='Building / Street number'
            name='buildingNumber'
            value={accountDetails?.buildingNumber}
            required={true}
            onChange={handleChange('buildingNumber')}
          />

          <Stretched gap={5}>
            <InputGroup
              label='Street line 1'
              name='streetLineOne'
              value={accountDetails?.streetLineOne}
              required={true}
              onChange={handleChange('streetLineOne')}
            />
            <InputGroup
              label='Street line 2'
              name='streetLineTwo'
              value={accountDetails?.streetLineTwo}
              onChange={handleChange('streetLineTwo')}
            />
          </Stretched>

          <InputGroup
            label='City'
            name='city'
            value={accountDetails?.city}
            required={true}
            onChange={handleChange('city')}
          />

          <Stretched gap={5}>
            {selectedCountry?.value === 'US' ? (
              <MultiSelect
                defaultValue=''
                label='State / Province / Region'
                options={stateOptions}
                value={selectedState}
                onChange={handleStateChange}
                required
                isMulti={false}
              />
            ) : (
              <InputGroup
                label='State / Province / Region'
                name='state'
                value={accountDetails?.state}
                required={true}
                onChange={handleChange('state')}
              />
            )}

            <InputGroup
              label='Zip / Postal code'
              name='zipCode'
              value={accountDetails?.zipCode}
              required={true}
              onChange={handleChange('zipCode')}
            />
          </Stretched>

          <div className='flex justify-end'>
            {!formValid ? (
              <p className='text-danger-medium'>
                Form field missing or invalid
              </p>
            ) : null}
          </div>
        </form>
      </Card>
    </Col>
  )
}
