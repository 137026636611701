import { useTSWagmi } from '@contexts'
import { simulateContract, writeContract } from '@wagmi/core'
import { useState } from 'react'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useSweepDistributor = () => {
  const { wagmiConfig } = useTSWagmi()
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [prepareError, setPrepareError] = useState<Maybe<any>>(null)

  const write = async (
    contractAddress: string,
    token: string,
    amount: string,
    chainId: number,
  ) => {
    setSubmitting(true)
    setPrepareError(null)

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi: [
          {
            inputs: [
              {
                internalType: 'contract IERC20',
                name: 'token',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
            ],
            name: 'sweepToken',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
        functionName: 'sweepToken',
        chainId,
        args: [token, amount],
      } as any)

      const writeContractResponse = await writeContract(wagmiConfig, request)
      setTransactionHash(writeContractResponse)
      return writeContractResponse
    } catch (e: any) {
      setPrepareError(e.message)
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
    error: prepareError || waitForTransactionResponse?.error,
  }
}
