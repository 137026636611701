import { useGetMyPolkadotWallets } from '@apiServices'
import { Text } from '@components'
import { useToast } from '@contexts'
import { LoaderButton } from '@newComponents'
import { useEffect, useState } from 'react'
import { EligibilityItemStatus } from './eligibility-item-status'
import { EventModal } from './event-modal'

interface PolkadotWalletEligibilityStatusProps {
  highlightColor: HexColorCode
  onMetRequirement: () => void
}

export const PolkadotWalletEligibilityStatus = ({
  highlightColor,
  onMetRequirement,
}: PolkadotWalletEligibilityStatusProps) => {
  const { showErrorToast } = useToast()
  const {
    data: userPolkadotWallets,
    isPending: arePolkadotWalletsPending,
    error: polkadotWalletsError,
  } = useGetMyPolkadotWallets()

  const [hasMetRequirement, setHasMetRequirement] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)

  useEffect(() => {
    if (polkadotWalletsError) {
      showErrorToast({ description: polkadotWalletsError.message })
    }
  }, [polkadotWalletsError, showErrorToast])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (hasMetRequirement) {
      setIsModalOpen(false)
      onMetRequirement()
    }
  }, [hasMetRequirement, onMetRequirement])

  const handleVerification = () => {
    setIsVerifying(true)
    // Simulate verification process with a 2 second delay
    setTimeout(() => {
      setIsVerifying(false)
      setHasMetRequirement(true)
    }, 3000)
  }

  return (
    <>
      <EventModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel='Verify Your Polkadot Wallet Ownership'
      >
        <div className='p-4 flex flex-col items-center gap-4'>
          <Text className='text-2xl font-bold text-gray-700'>
            Verify Your Polkadot Wallet Ownership
          </Text>
          <Text className='text-md font-medium text-gray-700'>
            Click below to initialize verification of your Polkadot wallet.
          </Text>
          <LoaderButton
            onClick={handleVerification}
            isLoading={arePolkadotWalletsPending || isVerifying}
            loadingText='Verifying...'
            text='Verify Wallet'
          />
        </div>
      </EventModal>
      <EligibilityItemStatus
        onClick={openModal}
        title='Verify Polkadot Wallet'
        progressStatus={hasMetRequirement ? 'completed' : 'incomplete'}
        highlightColor={highlightColor}
      />
    </>
  )
}
