import { EventPrivacyConfiguration, FileUploadResult } from '@customTypes'
import { useValidateParticipantsFile } from '@hooks'
import { useMemo, useState } from 'react'

interface InitialEventVisibilityOptions {
  isPrivate?: boolean
  eventPrivacyConfiguration?: EventPrivacyConfiguration
}

export function useEventVisibilityOptions({
  eventPrivacyConfiguration = {
    isWalletRequired: false,
    isEmailRequired: false,
    participantsFileUri: '',
  },
  isPrivate: _isPrivate = false,
}: InitialEventVisibilityOptions) {
  const [isPrivate, setIsPrivate] = useState(_isPrivate)
  const [isEmailRequired, setIsEmailRequired] = useState(
    eventPrivacyConfiguration?.isEmailRequired || false,
  )
  const [isWalletRequired, setIsWalletRequired] = useState(
    eventPrivacyConfiguration?.isWalletRequired || false,
  )
  const [uploadedFileUri, setUploadedFileUri] = useState<string | null>(
    eventPrivacyConfiguration?.participantsFileUri || null,
  )
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)

  const {
    isPending: isPendingValidation,
    uploadedData,
    uploadFileErrorMessage,
    areCsvEmailsValid,
    areCsvWalletsValid,
    resetUpload,
  } = useValidateParticipantsFile({
    participantsFileUri: uploadedFileUri,
    isEmailRequired,
    isWalletRequired,
  })

  const onIsPrivateToggled = () => {
    // Toggling to public, reset visibility configuration
    if (isPrivate) {
      resetVisibilityConfiguration()
      resetUpload()
    }

    setIsPrivate(!isPrivate)
  }

  const onEmailRequiredToggled = () => {
    if (isEmailRequired && !isWalletRequired) setIsWalletRequired(true)
    setIsEmailRequired(!isEmailRequired)
  }

  const onWalletRequiredToggled = () => {
    if (isWalletRequired && !isEmailRequired) setIsEmailRequired(true)
    setIsWalletRequired(!isWalletRequired)
  }

  const resetVisibilityConfiguration = () => {
    setIsEmailRequired(false)
    setIsWalletRequired(false)
    setUploadedFileName(null)
    setUploadedFileUri(null)
    // The uploadedData and uploadFileErrorMessage are managed by useValidateParticipantsFile
  }

  const onPrivateEventParticipantsFileUploaded = async (
    uploadedFile: FileUploadResult,
  ) => {
    setUploadedFileName(uploadedFile.filename)
    setUploadedFileUri(uploadedFile.s3Uri)
  }

  const isPrivacyConfigurationValid = useMemo(() => {
    // If not a private event, it's valid
    return (
      !isPrivate ||
      // Otherwise, make sure at least one field is required,
      // and that any required fields are valid
      ((isEmailRequired || isWalletRequired) &&
        (!isEmailRequired || areCsvEmailsValid) &&
        (!isWalletRequired || areCsvWalletsValid))
    )
  }, [
    isPrivate,
    isEmailRequired,
    isWalletRequired,
    areCsvEmailsValid,
    areCsvWalletsValid,
  ])

  return {
    isPrivate,
    onIsPrivateToggled,

    isEmailRequired,
    onEmailRequiredToggled,
    isWalletRequired,
    onWalletRequiredToggled,

    onPrivateEventParticipantsFileUploaded,

    uploadedFileName,
    uploadedFileUri,
    areCsvEmailsValid,
    areCsvWalletsValid,

    isPendingValidation,

    uploadedData,
    uploadFileErrorMessage,

    isPrivacyConfigurationValid,
  }
}
