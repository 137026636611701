import { useGetAllChainPaymentMethods, useGetChains } from '@apiServices'
import { NetworkContext } from '@contexts'
import { hexToDecimal } from '@utils'
import { FC, ReactNode } from 'react'
import { Blockchain } from 'tokensoft-shared-types'

interface NetworkProviderProps {
  children?: ReactNode
}

const ethereumBlockchain: Blockchain = {
  id: 1,
  name: 'Ethereum',
  logoUri: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  blockExplorerUri: 'https://etherscan.io/',
  rpcUri: 'https://mainnet.infura.io/v3',
  symbol: 'ETH',
  decimals: 18,
  subgraphUri:
    'https://subgraph.satsuma-prod.com/945b0ac184df/tokensoft-1/eth-mainnet/api',
  nativePriceOracleAddress: '0x5f4ec3df9cbd43714fe2740f5e3616155c5b8419',
  nativePriceOracleHeartbeat: null,
  trustedForwarderAddress: null,
  saleFactoryAddress: '0xA464d007Ddc98cE0407584aaEF1c5a80e057e142',
  saleFactoryV3Address: '0x1B08223F6AE8A4c141291E1c9801Ec5D12eE58bb',
  saleFactoryV4Address: null,
  stakingAddress: null,
  stakingV4Address: null,
  platformFeeRecipient: '0x52c263698B5B11AaCAf0f74333DC921B26FFA5b7',
  continuousVestingDistributorFactoryAddress: null,
  continuousVestingDistributorFactoryV4Address: null,
  continuousVestingDistributorFactoryV5Address: null,
  trancheVestingDistributorFactoryAddress: null,
  trancheVestingDistributorFactoryV4Address: null,
  trancheVestingDistributorFactoryV5Address: null,
  alchemyBaseUri: `https://eth-mainnet.alchemyapi.io/v2/${import.meta.env.VITE_ALCHEMY_MAINNET_KEY}`,
  useEip1559: false,
  enabled: true,
}

export const NetworkProvider: FC<NetworkProviderProps> = ({ children }) => {
  const { data: chains, isPending: isPendingChains } = useGetChains()
  const {
    data: allChainPaymentMethods,
    isPending: isPendingAllChainPaymentMethods,
  } = useGetAllChainPaymentMethods()

  const supportedChains = chains?.filter((chain) => chain.enabled === true)

  const getChainDetails = (chainId: ID): Blockchain => {
    const decimalChainId =
      typeof chainId === 'string' ? hexToDecimal(chainId) : Number(chainId)
    const chainDetails = supportedChains?.find(
      (chain) => chain.id == decimalChainId,
    )
    if (!chainDetails) {
      // Default to ETH; usually only happens when chains are still loading
      return ethereumBlockchain
    }

    return chainDetails
  }

  const getNativeBaseCurrencyPriceOracle = (
    chainId?: number,
  ): Maybe<EvmAddress> => {
    if (!chainId) {
      return null
    }

    const decimalChainId =
      typeof chainId === 'string' ? hexToDecimal(chainId) : Number(chainId)
    if (allChainPaymentMethods === undefined) {
      return null
    }

    const nativePaymentDetails = allChainPaymentMethods.find(
      (paymentMethod) => paymentMethod.native && decimalChainId === chainId,
    )
    if (!nativePaymentDetails) {
      return null
    }

    return nativePaymentDetails.nativePriceOracleAddress ?? null
  }

  return (
    <>
      <NetworkContext.Provider
        value={{
          supportedNetworks: supportedChains ?? [],
          getNetworkDetails: getChainDetails,
          getNativeBaseCurrencyPriceOracle: getNativeBaseCurrencyPriceOracle,
          loading: isPendingChains || isPendingAllChainPaymentMethods,
        }}
      >
        {children}
      </NetworkContext.Provider>
    </>
  )
}
