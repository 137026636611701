import { Modal, ModalContext } from '@contexts'
import { FC, ReactNode, useState } from 'react'

interface ModalProviderProps {
  children?: ReactNode
}

export interface ModalOptions {
  content?: ReactNode
  onClose?: Function
  isOpen?: boolean
  className?: string
  disableClose?: boolean
}

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [modal, setModal] = useState<Modal | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const showModal = (options: ModalOptions = {}) => {
    setModal({
      content: options.content || <></>,
      onClose: options.onClose,
      disableClose: options.disableClose,
      className: options.className,
    })

    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)

    if (modal && modal.onClose) {
      modal.onClose()
    }

    setModal(null)
  }

  return (
    <ModalContext.Provider
      value={{
        modal,
        setModal,
        showModal,
        closeModal,
        isOpen: open,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
