import { useTSWagmi } from '@contexts'
import { getErc20ABI } from '@utils'
import { simulateContract, writeContract } from '@wagmi/core'
import BigNumber from 'bignumber.js'
import { useState } from 'react'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useApproveErc20Transfer = () => {
  const { wagmiConfig } = useTSWagmi()
  const [submitApprovalError, setSubmitApprovalError] =
    useState<Maybe<Error>>(null)
  const [transactionHash, setTransactionHash] =
    useState<Maybe<EvmAddress>>(null)
  const [isPendingSubmitTransaction, setIsPendingSubmitTransaction] =
    useState(false)

  const approveErc20Transfer = async (
    chainId: ChainId,
    tokenAddress: EvmAddress,
    recipientAddress: EvmAddress,
    erc20AmountInWei: BigNumber,
  ) => {
    setIsPendingSubmitTransaction(true)
    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: tokenAddress,
        abi: getErc20ABI(),
        functionName: 'approve',
        chainId,
        args: [recipientAddress, BigInt(erc20AmountInWei.toString())],
      })

      const hash = await writeContract(wagmiConfig, request)
      setTransactionHash(hash)
    } catch (e) {
      setSubmitApprovalError(e as Error)
    } finally {
      setIsPendingSubmitTransaction(false)
    }
  }

  const {
    data: transactionReceipt = null,
    isLoading: isLoadingTransactionReceipt,
    error: waitForTransactionReceiptError,
  } = useWaitForTransactionReceipt({
    hash: transactionHash ?? '0x',
    query: {
      enabled: transactionHash !== null,
    },
  })

  return {
    approveErc20Transfer,
    receipt: transactionReceipt,
    isPending: isPendingSubmitTransaction || isLoadingTransactionReceipt,
    error: submitApprovalError || waitForTransactionReceiptError,
  }
}
