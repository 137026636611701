import { createContext, useContext } from 'react'

export interface RainbowkitContextProps {}

export const RainbowkitContext =
  createContext<Maybe<RainbowkitContextProps>>(null)
RainbowkitContext.displayName = 'RainbowkitContext'

export const useRainbowkit = (): RainbowkitContextProps => {
  const context = useContext(RainbowkitContext)

  if (!context) {
    throw new Error(
      'RainbowkitProvider context is undefined, please verify you are calling useRainbowkit() as child of a <RainbowkitProvider> component.',
    )
  }

  return context
}
