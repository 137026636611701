import { useAccountApiClient } from '@apiClients'
import { useToast } from '@contexts'
import { UploadedDoc } from '@customTypes'
import { FC, useCallback } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { MdHighlightOff } from 'react-icons/md'
import './file-upload.css'

const Preview = ({ file, onRemove }: any) => {
  return (
    <div className='w-full flex items-center justify-between'>
      <div className='flex items-center gap-2 pb-4'>
        <img className='dzu-previewImage' src={URL.createObjectURL(file)} />
        <span>{file.name}</span>
      </div>
      <MdHighlightOff
        style={{ justifySelf: 'end' }}
        className='cursor-pointer'
        color='var(--primary)'
        onClick={() => onRemove(file)}
      />
    </div>
  )
}

interface FileUploadProps {
  maxFiles?: number
  maxSizeBytes?: number
  uploadUrl: string
  fileTypes?: Accept
  onFileChange: Function
  docsUploaded: UploadedDoc[]
}

export const FileUpload: FC<FileUploadProps> = ({
  maxFiles = 10,
  maxSizeBytes = 10 * 1000000, // 10 MB
  uploadUrl,
  // fileTypes = 'image/*,application/pdf',
  fileTypes = {
    'image/*': ['.jpg', '.jpeg', '.png'],
    'application/pdf': ['.pdf'],
  },
  onFileChange, // parent docsUploaded state setter
  docsUploaded, // parent slice of state
}) => {
  const client = useAccountApiClient()
  const { showErrorToast } = useToast()

  const onDrop = useCallback(
    async (acceptedFiles) => {
      for (const file of acceptedFiles) {
        const data = new FormData()
        data.append('file', file, file.name)

        try {
          const res = await client(uploadUrl, {
            headers: { 'Content-Type': 'multipart/form-data' },
            data,
          })

          onFileChange((prev: any) => [
            ...prev,
            { id: file.path, documentId: res.id },
          ])
        } catch (error: any) {
          showErrorToast({ description: error.message })
        }
      }
    },
    [client, uploadUrl, onFileChange, showErrorToast],
  )

  const onRemove = useCallback(
    async (file) => {
      const { documentId } =
        docsUploaded.find((doc) => doc.id === file.path) || {}
      if (documentId) {
        try {
          await client(`accounts/documents/${documentId}`, {
            method: 'delete',
          })
          onFileChange((prev: any) =>
            prev.filter((doc: any) => doc.id !== file.path),
          )
        } catch (error: any) {
          showErrorToast({ description: error.message })
        }
      }
    },
    [client, docsUploaded, onFileChange, showErrorToast],
  )

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({
      onDrop,
      accept: fileTypes,
      maxFiles,
      maxSize: maxSizeBytes,
    })

  const previews = acceptedFiles.map((file) => (
    <Preview key={file.name} file={file} onRemove={onRemove} />
  ))

  fileRejections.forEach(({ file, errors }) => {
    if (errors.find((e) => e.code === 'file-too-large')) {
      showErrorToast({
        description: `File too large - the maximum file size is ${maxSizeBytes / 1000000} MB`,
      })
    }
  })

  return (
    <div
      {...getRootProps({ className: 'dropzone' })}
      style={{ padding: '1.8rem 1.8rem 0' }}
    >
      <input {...getInputProps()} />
      <p>Drop files here to upload</p>
      <div>{previews}</div>
    </div>
  )
}
