import { useUpdateEventPrivacyConfiguration } from '@apiServices'
import {
  BackButton,
  ButtonRow,
  EventVisibilityOptions,
  PageSubtitle,
  PageTitle,
  SaveButton,
  Step,
  StepFooter,
  StepHeader,
} from '@components'
import { useEvent, useProject, useToast, WizardContext } from '@contexts'
import { EventPrivacyConfiguration } from '@customTypes'
import { useEventVisibilityOptions } from '@hooks'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Wizard } from 'react-use-wizard'

export const EditEventPrivacyWizardPage = () => {
  const navigate = useNavigate()
  const [wizardState, setWizardState] = useState<any>({})

  const { eventId } = useParams()
  const { state: privacyConfiguration } = useLocation()

  // Fetch event privacy configuration
  const { mutate: update, isPending } = useUpdateEventPrivacyConfiguration()

  const { showSuccessToast, showErrorToast } = useToast()

  const projectOverview = useProject()
  const eventOverview = useEvent()

  const goBack = (latestConfiguration: EventPrivacyConfiguration) => {
    // Unfortunately, we can't pass state with navigate(-1) syntax. So hardcoding
    // the path to the edit page.
    // project/:id/event/:eventId/edit
    navigate(
      `/admin/project/${projectOverview.project?.id}/event/${eventOverview.event?.id}/edit`,
      {
        state: latestConfiguration,
      },
    )
  }

  const {
    isPrivate,
    isEmailRequired,
    isWalletRequired,
    uploadedFileName,
    uploadedFileUri,
    isPendingValidation,
    uploadedData,
    uploadFileErrorMessage,
    onIsPrivateToggled,
    onEmailRequiredToggled,
    onWalletRequiredToggled,
    onPrivateEventParticipantsFileUploaded,
    isPrivacyConfigurationValid,
  } = useEventVisibilityOptions({
    eventPrivacyConfiguration: privacyConfiguration ?? undefined,
    isPrivate: !!privacyConfiguration,
  })

  const handleSave = async () => {
    if (
      eventId === undefined ||
      isNaN(Number(eventId)) ||
      uploadedFileUri === null
    ) {
      showErrorToast({
        description: 'Event ID or privacy configuration is missing.',
      })
      return
    }
    const privacyConfiguration = {
      isEmailRequired,
      isWalletRequired,
      participantsFileUri: uploadedFileUri,
    }
    update(
      {
        eventId: Number(eventId),
        privacyConfiguration,
      },
      {
        onSuccess: () => {
          showSuccessToast({ description: 'Successfully updated event.' })
          // Pass back new configuration
          goBack(privacyConfiguration)
        },
        onError: (error) => {
          showErrorToast({ description: error.message })
        },
      },
    )
  }

  const handleCancel = () => {
    // Just pass back what was passed in
    goBack(privacyConfiguration)
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <PageTitle>Event Visibility</PageTitle>
            <PageSubtitle>
              If you would like to make an event private, keep in mind that only
              users on the uploaded list of emails and/or wallets will be able
              to visit the event page and participate. This action is
              irreversible - once an event is private, it cannot be made public
              again. If you have any questions, please contact support.
            </PageSubtitle>
          </StepHeader>

          <EventVisibilityOptions
            isPrivate={isPrivate}
            onIsPrivateToggled={onIsPrivateToggled}
            isEmailRequired={isEmailRequired}
            isWalletRequired={isWalletRequired}
            uploadedFileName={uploadedFileName}
            isPendingValidation={isPendingValidation}
            uploadedData={uploadedData}
            uploadFileErrorMessage={uploadFileErrorMessage}
            onEmailRequiredToggled={onEmailRequiredToggled}
            onWalletRequiredToggled={onWalletRequiredToggled}
            onPrivateEventParticipantsFileUploaded={
              onPrivateEventParticipantsFileUploaded
            }
          />

          <StepFooter hideProgressBar={true}>
            <ButtonRow place='between'>
              <BackButton label={'Cancel'} onClick={handleCancel} />
              <SaveButton
                // Disable save button if loading, privacy configuration is invalid, or not private
                disabled={
                  isPending || !isPrivacyConfigurationValid || !isPrivate
                }
                onClick={handleSave}
                saving={isPending}
              />
            </ButtonRow>
          </StepFooter>
        </Step>
      </Wizard>
    </WizardContext.Provider>
  )
}
