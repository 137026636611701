import { DEPLOY_VESTING_TYPE_OPTIONS } from '@enums'
import { DeployOptions } from 'custom-types/deploy-options'
import { useState } from 'react'
import { ContractFunctionArgs } from 'viem'
import { useDeployDistributorWithFactory } from './use-deploy-distributor'
import { useGetChains } from '@api/services'
import {
  getContinuousVestingMerkleDistributorFactoryABI,
  getContinuousVestingMerkleDistributorFactoryV5ABI,
  getTrancheVestingMerkleDistributorFactoryABI,
  getTrancheVestingMerkleDistributorFactoryV5ABI,
} from '@utils/abi'
import { useWagmiAccount } from '@hooks/use-wagmi-account'

export const useDeployDistributor = (
  options: Pick<DeployOptions, 'useExperimentalContractFeatures'>,
) => {
  const [vestingType, setVestingType] = useState<
    DEPLOY_VESTING_TYPE_OPTIONS | undefined
  >()
  const { chain: chainUserIsConnectedTo } = useWagmiAccount()
  const { data: chains } = useGetChains()

  const currentChainInfo = chains?.find(
    (n) => n.id === chainUserIsConnectedTo?.id,
  )

  const continuousVestingDistributorFactoryAddress =
    options.useExperimentalContractFeatures
      ? currentChainInfo?.continuousVestingDistributorFactoryV5Address
      : currentChainInfo?.continuousVestingDistributorFactoryAddress

  const trancheVestingDistributorFactoryAddress =
    options.useExperimentalContractFeatures
      ? currentChainInfo?.trancheVestingDistributorFactoryV5Address
      : currentChainInfo?.trancheVestingDistributorFactoryAddress

  if (continuousVestingDistributorFactoryAddress == null) {
    throw new Error('no address for continuous vesting distributor factory')
  }

  if (trancheVestingDistributorFactoryAddress == null) {
    throw new Error('no address for tranche vesting distributor factory')
  }

  const {
    deployedAddress: deployedContinuousVestingMerkleDistributorAddress,
    write: deployContinuousVestingMerkleDistributor,
    isDeploying: isDeployingContinuousVestingMerkleDistributor,
    response: deployContinuousVestingMerkleDistributorResponse,
    receipt: deployContinuousVestingMerkleDistributorReceipt,
    error: deployContinuousVestingMerkleDistributorError,
  } = useDeployDistributorWithFactory({
    ...options,
    address: continuousVestingDistributorFactoryAddress,
    abi: options.useExperimentalContractFeatures
      ? getContinuousVestingMerkleDistributorFactoryV5ABI()
      : getContinuousVestingMerkleDistributorFactoryABI(),
  })

  const {
    deployedAddress: deployedTrancheVestingMerkleDistributorAddress,
    write: deployTrancheVestingMerkleDistributor,
    isDeploying: isDeployingTrancheVestingMerkleDistributor,
    response: deployTrancheVestingMerkleDistributorResponse,
    receipt: deployTrancheVestingMerkleDistributorReceipt,
    error: deployTrancheVestingMerkleDistributorError,
  } = useDeployDistributorWithFactory({
    ...options,
    address: trancheVestingDistributorFactoryAddress,
    abi: options.useExperimentalContractFeatures
      ? getTrancheVestingMerkleDistributorFactoryV5ABI()
      : getTrancheVestingMerkleDistributorFactoryABI(),
  })

  const deployDistributor = async (
    vestingType: DEPLOY_VESTING_TYPE_OPTIONS,
    params: { args: ContractFunctionArgs },
  ) => {
    setVestingType(vestingType)
    if (
      vestingType === DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS ||
      vestingType === DEPLOY_VESTING_TYPE_OPTIONS.INSTANT
    ) {
      return deployContinuousVestingMerkleDistributor(params)
    } else if (vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY) {
      return deployTrancheVestingMerkleDistributor(params)
    }
  }

  return {
    deployDistributor,
    ...(vestingType === DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS ||
    vestingType === DEPLOY_VESTING_TYPE_OPTIONS.INSTANT
      ? {
          deployedAddress: deployedContinuousVestingMerkleDistributorAddress,
          isPending:
            isDeployingContinuousVestingMerkleDistributor ||
            deployContinuousVestingMerkleDistributorResponse?.isLoading,
          receipt: deployContinuousVestingMerkleDistributorReceipt,
          error: deployContinuousVestingMerkleDistributorError,
        }
      : {
          deployedAddress: deployedTrancheVestingMerkleDistributorAddress,
          isPending:
            isDeployingTrancheVestingMerkleDistributor ||
            deployTrancheVestingMerkleDistributorResponse?.isLoading,
          receipt: deployTrancheVestingMerkleDistributorReceipt,
          error: deployTrancheVestingMerkleDistributorError,
        }),
  }
}
