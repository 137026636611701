import { useTSWagmi } from '@contexts'
import { getFlatPriceSaleABI } from '@utils'
import { simulateContract, writeContract } from '@wagmi/core'
import { useState } from 'react'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useSweepNative = () => {
  const { wagmiConfig } = useTSWagmi()
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const write = async (
    chainId: any,
    toAddress: any,
    proof: any,
    payee: any,
  ) => {
    setSubmitting(true)

    console.log('preparing sweep Native', proof)

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: toAddress,
        abi: getFlatPriceSaleABI(),
        functionName: 'withdrawPayments',
        chainId,
        args: [payee],
      } as any)

      const writeContractResponse = await writeContract(wagmiConfig, request)
      setTransactionHash(writeContractResponse)
      console.log('sweep Native transaction response', writeContractResponse)
      return writeContractResponse
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  }
}
