import { Button } from '@components'
import { useToast } from '@contexts'
import React, { useCallback, useRef } from 'react'
import { FileUploadPreview } from './file-upload-preview'

interface FileUploadProps {
  allowedFileExtensions?: string[]
  maxFileSizeMB?: number
  onFileChange: (files: File[]) => void
  files: File[]
  uploadStyle?: 'dropzone' | 'button'
}

export const FileUpload: React.FC<FileUploadProps> = ({
  allowedFileExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'txt'],
  maxFileSizeMB = 10,
  onFileChange,
  files,
  uploadStyle = 'button',
}) => {
  const { showErrorToast } = useToast()

  const fileInputRef = useRef<HTMLInputElement>(null)

  const validateFile = (file: File) => {
    const extension = file.name.split('.').pop()?.toLowerCase()
    const isAllowedExtension =
      !allowedFileExtensions.length ||
      allowedFileExtensions.includes(extension || '')
    const isAllowedSize = file.size <= maxFileSizeMB * 1024 * 1024

    if (!isAllowedExtension) {
      return `Invalid file type: ${file.name}`
    }
    if (!isAllowedSize) {
      return `File too large (${file.name}) - Max size is ${maxFileSizeMB} MB`
    }
    return null
  }

  const handleFiles = (fileList: FileList) => {
    const newFiles: File[] = []
    const newErrors: string[] = []

    Array.from(fileList).forEach((file) => {
      if (files.some((f) => f.name === file.name)) {
        showErrorToast({
          description: `File ${file.name} is already selected.`,
        })
        return
      }

      const error = validateFile(file)
      if (error) {
        newErrors.push(error)
      } else {
        newFiles.push(file)
      }
    })

    if (newFiles.length > 0) {
      onFileChange([...files, ...newFiles])
    }

    if (newErrors.length > 0) {
      const message = newErrors.join(' ')
      showErrorToast({ description: message })
    }
  }

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleFiles(e.target.files)
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileRemoved = (file: File) => {
    onFileChange(files.filter((f) => f !== file))
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Reset input to allow re-uploading removed files
    }
  }

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files) {
      handleFiles(e.dataTransfer.files)
    }
  }, [])

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const buttonClasses =
    'px-4 py-2 bg-primary text-white rounded cursor-pointer transition'

  return (
    <div>
      {/* Dropzone or Button Upload */}
      {uploadStyle === 'button' && (
        <Button
          type='button'
          onClick={handleButtonClick}
          className={buttonClasses}
        >
          Upload Files
        </Button>
      )}

      {uploadStyle === 'dropzone' && (
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className='flex flex-col gap-2 items-center border-2 border-dashed border-gray-300 p-4 rounded-lg text-center'
        >
          <p className='select-none'>
            Drag & drop files here, or click to upload
          </p>
          <Button
            size='xs'
            className='cursor-pointer text-primary'
            onClick={handleButtonClick}
          >
            Browse Files
          </Button>
        </div>
      )}

      {/* Hidden File Input */}
      <input
        id='file-upload-input'
        data-testid='file-upload-input'
        ref={fileInputRef}
        accept={allowedFileExtensions.map((ext) => `.${ext}`).join(',')}
        type='file'
        multiple
        onChange={handleFileInput}
        className='hidden'
      />

      {/* Uploaded Files */}
      <div className='mt-2 flex flex-wrap gap-4'>
        {files.map((file) => (
          <FileUploadPreview
            key={file.name}
            file={file}
            onRemove={() => handleFileRemoved(file)}
          />
        ))}
      </div>
    </div>
  )
}
