import { useTSWagmi } from '@contexts'
import { getAdvancedDistributorABI } from '@utils'
import { simulateContract, writeContract } from '@wagmi/core'
import { useState } from 'react'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useUpdateDistributorOwner = () => {
  const { wagmiConfig } = useTSWagmi()
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const write = async (chainId: any, contractAddress: any, args: any) => {
    setSubmitting(true)

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: contractAddress,
        abi: getAdvancedDistributorABI(),
        functionName: 'transferOwnership',
        chainId,
        args,
      } as any)

      const writeContractResponse = await writeContract(wagmiConfig, request)
      setTransactionHash(writeContractResponse)
      return writeContractResponse
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  }
}
