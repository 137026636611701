import { useNetworks, useTSWagmi } from '@contexts'
import { getFlatPriceSaleFactoryABI } from '@utils'
import { simulateContract, writeContract } from '@wagmi/core'
import { useState } from 'react'
import { TransactionReceipt } from 'viem'
import {
  useAccount as useWagmiAccount,
  useWaitForTransactionReceipt,
} from 'wagmi'

export const useDeploySale = () => {
  const { wagmiConfig } = useTSWagmi()
  const { chain } = useWagmiAccount()
  const { supportedNetworks } = useNetworks()
  const [transactionHash, setTransactionHash] = useState<Maybe<any>>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const network = supportedNetworks?.find((n) => n.id === chain?.id)
  const factoryAddress = network?.saleFactoryAddress
  const factoryV3Address = network?.saleFactoryV3Address
  const factoryV4Address = network?.saleFactoryV4Address
  const factoryVersion = factoryV4Address
    ? 'v4.0'
    : factoryV3Address
      ? 'v3.0'
      : 'v2.x'

  const write = async (args: any) => {
    setSubmitting(true)
    let updatedArgsForSaleVersion = [...args]

    // v2 has unique arguments; v3 and v4 have the same
    if (factoryVersion === 'v2.x') {
      updatedArgsForSaleVersion = [
        ...args.slice(0, 5),
        ...args.slice(6, 8),
        ...args.slice(9),
      ]
    }

    try {
      const { request } = await simulateContract(wagmiConfig, {
        address: factoryV4Address ?? factoryV3Address ?? factoryAddress,
        abi: getFlatPriceSaleFactoryABI(factoryVersion),
        functionName: 'newSale',
        chain,
        args: updatedArgsForSaleVersion,
      } as any)

      const writeContractResponse = await writeContract(wagmiConfig, request)
      setTransactionHash(writeContractResponse)
      return writeContractResponse
    } catch (error) {
      console.error({ error })
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    write,
    isSubmitting: submitting,
    response: { ...waitForTransactionResponse },
    data: waitForTransactionResponse?.data || ({} as TransactionReceipt),
    error: waitForTransactionResponse?.error || ({} as Error),
  }
}
